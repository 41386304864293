import { useEffect, useState } from 'react'
import { tidyName } from '../../../helper'
import RCTabs from '../../Tabs/rcTabs'
import BlockMainDetails from './blockMainDetails'
import BlockRates from './blockRates'
import ManageLocker from './manageLocker'
import { useDispatch, useSelector } from 'react-redux'
import { request } from '../../../Redux/Sagas/requests/api'
import Loader from 'react-loaders'
import { primary_color } from '../../../Helper/uiHelper'
import BlockMaintanance from './blockMaintanance'
import { clearCurrentRowData, clearParentTableData } from '../../../Redux/Reducers/CMS'
import CodesTable from './codes/codeTable'

const getTabs = (tabs, blockDetails, setBlocketails, onCloseForm, isPlannedBlock) => {
  console.log('get tabs:::::::', tabs)
  return tabs?.map((item, index) => {
    if (!blockDetails?.id && index > 0) return
    return {
      title: tidyName(item?.name),
      getContent: () => {
        switch (item?.template_name) {
          case 'planned_block_item':
          case 'active_block_item':
            return (
              <ManageLocker
                blockDetails={blockDetails}
                setBlocketails={setBlocketails}
                onCloseForm={onCloseForm}
                isPlannedBlock={isPlannedBlock}></ManageLocker>
            )
          case 'planned_block_rates':
          case 'active_block_rates':
            return <BlockRates blockDetails={blockDetails} setBlocketails={setBlocketails} form={item} onCloseForm={onCloseForm}></BlockRates>
          case 'planned_block_main_details':
          case 'active_block_main_details':
            return (
              <BlockMainDetails blockDetails={blockDetails} setBlocketails={setBlocketails} form={item} onCloseForm={onCloseForm}></BlockMainDetails>
            )
          case 'planned_block_maintanance':
          case 'active_block_maintanance':
            return (
              <BlockMaintanance blockDetails={blockDetails} setBlocketails={setBlocketails} form={item} onCloseForm={onCloseForm}></BlockMaintanance>
            )
          case "active_block_code":
            return <CodesTable blockDetails={blockDetails} setBlocketails={setBlocketails} form={item}></CodesTable>
        }
      },
    }
  })
}

const PlannedBlockTabs = ({ addFormList, addDataDetail, setAddGridDataDetail, isPlannedBlock }) => {
  const dispatch = useDispatch()
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const [blockDetails, setBlocketails] = useState()
  const [tabs, setTabs] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [errorInBlockDetails, setErrorInBlockDetails] = useState(false)

  useEffect(() => {
    if (!addDataDetail?.isAddNew) {
      request('get-location-data', 'POST', { location_id: currentRow?.[1]?.id }).then((res) => {
        if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
          setBlocketails(res?.data)
        } else {
          setErrorInBlockDetails(true)
        }
      })
    }
  }, [currentRow?.length])

  const onCloseForm = () => {
    if (!addDataDetail?.isAddNew) {
      dispatch(clearCurrentRowData())
      dispatch(clearParentTableData())
    }

    setAddGridDataDetail({ open: false })
  }

  useEffect(() => {
    const tabsData = getTabs(addFormList, blockDetails, setBlocketails, onCloseForm, isPlannedBlock)
    setTabs(tabsData)
  }, [addFormList, blockDetails])

  console.log('add form list:::::::::::', addFormList, addDataDetail, currentRow)
  if (errorInBlockDetails) return <div class='alert alert-danger mt-5'>Error while fetching block details. Please try after sometime</div>
  if (!addDataDetail?.isAddNew && !blockDetails?.id)
    return (
      <div className='d-flex align-items-center justify-content-center' style={{ height: '10rem' }}>
        <Loader type='ball-pulse' color={primary_color} />
      </div>
    )
  return (
    <div className='p-3'>
      <RCTabs
        items={tabs}
        selectedTabKey={activeTab || 0}
        activeTab={activeTab}
        onChange={(e) => {
          setActiveTab(parseInt(e))
        }}
      />
    </div>
  )
}

export default PlannedBlockTabs
