import { Button } from 'reactstrap'

const TransactionLogAction = ({ onClick }) => {
  return (
    <>
      <Button
        className='bg-warning px-2 rounded cursor-pointer text-white'
        style={{ border: 'none', paddingTop: '0.3rem', paddingBottom: '0.3rem' }}
        onClick={() => {
          onClick()
        }}>
        Refund Request
      </Button>
    </>
  )
}

export default TransactionLogAction
