import { AvForm } from 'availity-reactstrap-validation'
import RenderFields from '../../../CMSComponent/Forms/renderFields'
import { useState } from 'react'
import { primary_color } from '../../../Helper/uiHelper'
import { request } from '../../../Redux/Sagas/requests/api'
import { useDispatch, useSelector } from 'react-redux'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'

const BlockMainDetails = ({ setBlocketails, blockDetails, form, onCloseForm }) => {
  const dispatch = useDispatch()
  const rowData = useSelector((state) => state.CMS.currentRow)
  const [formFieldValue, setFormFieldValue] = useState()
  const [loading, setLoading] = useState(false)

  const onSubmitBlockDetails = (values) => {
    const domain_id = rowData?.[0]?.id
    request('location-manage', 'POST', {
      locations: [{ ...values, domain_id, id: blockDetails?.id }],
    }).then((res) => {
      if (res.status === 200 || res.status === 202 || res.status === 201) {
        dispatch(setNotification({ type: 'success', message: 'Form submitted successfully!' }))
        request('get-location-data', 'POST', { location_id: res?.data?.id }).then((res) => {
          if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
            setBlocketails(res?.data)
          }
          setLoading(false)
        })
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message || 'Something went wrong!' }))
        setLoading(false)
      }
    })
  }

  return (
    <div className='m-5'>
      <AvForm
        onValidSubmit={(e, values) => {
          setLoading(true)
          onSubmitBlockDetails(values)
          console.log('form values::::::::::', values)
        }}>
        <RenderFields
          allForm={[form]}
          content={[form]}
          twoCol={true}
          // defaultFormValue={defaultSingleFormValue}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
          defaultFormValue={blockDetails}
        />
        <div className='d-flex justify-content-end mt-5'>
          {' '}
          <button
            className='btn rounded-pill mt-2 text-danger border-danger mr-3'
            type='button'
            onClick={(e) => {
              onCloseForm()
              e.preventDefault()
              e.stopPropagation()
            }}
            style={{ fontWeight: 700, width: '12rem' }}>
            Cancel
          </button>
          <button
            className='btn text-white rounded-pill mt-2'
            disabled={!!loading}
            type='submit'
            style={{ background: primary_color, fontWeight: 700, width: '14rem' }}>
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </AvForm>
    </div>
  )
}

export default BlockMainDetails
