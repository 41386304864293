import React, { useState, useEffect } from 'react'
import { Table, Input, Pagination, PaginationItem, PaginationLink, Button } from 'reactstrap'

import Loader from 'react-loaders'
import { primary_color } from '../../../../Helper/uiHelper'
import useFetchSchoolNotes from '../../../../Queries/BlockPlan/useFetchSchoolNotes'
import IconSet from '../../../../CMSComponent/icon'
import AddSchoolNotesForm from './addSchoolNotesForm'
import { useDispatch, useSelector } from 'react-redux'
import useFetchNotesType from '../../../../Queries/BlockPlan/useFetchNotesType'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'
import NotesTypeFilter from './notesTypeFilter'
import { request } from '../../../../Redux/Sagas/requests/api'

const checkUserSelected = (users, uuid) => {
  if (users?.includes(uuid)) {
    return true
  } else {
    return false
  }
}

const NotesTable = ({ blockDetails, tab }) => {
  const dispatch = useDispatch()
  const currentRowData = useSelector((state) => state.CMS.currentRow)

  const [page, setPage] = useState()
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [selectedSearchParam, setSelectedSearchParam] = useState()
  const [search, setSearch] = useState()
  const [filterPage, setFilterPage] = useState([])

  const [selectedNotes, setSelectedNotes] = useState([])
  let isAllFilteredChecked = true

  const uncheckedFilter = filterPage?.filter((item) => !item.checked)
  const checkedFilter = filterPage?.filter((item) => item.checked)?.map((item)=>item?.id)
  if (uncheckedFilter?.length > 0) {
    isAllFilteredChecked = false
  }

  const handleNotesSelection = (checked, note) => {
    if (checked) {
      const newUser = [...selectedNotes]
      newUser?.push(note)
      setSelectedNotes(newUser)
    } else {
      const newUser = [...selectedNotes]
      let index
      newUser?.map((item, i) => {
        if (item?.id === note?.id) {
          index = i
        }
      })
      newUser?.splice(index, 1)
      setSelectedNotes(newUser)
    }
  }

  const {
    data: notesData,
    isLoading,
    refetch: getNotesData,
  } = useFetchSchoolNotes({
    domain_id: currentRowData?.[0]?.id,
    page,
    [selectedSearchParam]: search,
    'note_type_id': checkedFilter,
  })

  useEffect(() => {
    request('get-notes-type', 'POST', {}).then((res) => {
      const filters = res?.data?.data?.map((item) => {
        return {
          id: item?.id,
          checked: true,
          label: item?.name,
        }
      })
      setFilterPage(filters)
    })
  }, [])

  const selectedLockerUUID = selectedNotes?.map((item) => item?.id)

  if (isFormOpen)
    return (
      <AddSchoolNotesForm
        grid_id={tab?.component_call_form?.[0]?.main_grid?.id}
        onCloseForm={() => setIsFormOpen(false)}
        getNotesData={getNotesData}></AddSchoolNotesForm>
    )
  return (
    <div className='px-5 mt-4 merge-user-table'>
      <div className='d-flex justify-content-between notes-type-table' style={{marginLeft:'14.5rem'}}>
        <div>
          <AvForm>
            <div className='d-flex'>
              <div className='mr-3'>
                <AvField
                  type='select'
                  name='note-type'
                  className='mr-3'
                  value={selectedSearchParam}
                  onChange={(e) => {
                    setSelectedSearchParam(e.target.value)
                  }}>
                  <option value=''>Select search parameter</option>
                  <option value='title'>Title</option>
                  <option value='details'>Details</option>
                </AvField>
              </div>

              <AvField
                type='text'
                name='search'
                placeholder={'Search.'}
                value={search}
                onChange={(e) => {
                  if (!selectedSearchParam) {
                    dispatch(setNotification({ type: 'error', message: 'Please select search parameter' }))
                    return
                  }
                  setSearch(e.target.value)
                }}></AvField>
            </div>
          </AvForm>
        </div>
        <div className='mb-2' style={{ marginTop: '-0.5rem' }}>
          <Button
            className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
            // onClick={onPrintClick}
            disabled={true}>
            <IconSet icon='fa-regular fa-print' color='light' />
          </Button>
          <Button
            className={`btn-success border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
            onClick={() => {
              setIsFormOpen(true)
            }}>
            <IconSet icon='fa-solid fa-plus' color='light' />
          </Button>
          <Button
            className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
            onClick={() => {
              getNotesData()
            }}>
            <IconSet icon='fa-solid fa-sync' color='light' />
          </Button>
          <Button
            className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
            onClick={() => {
              setSelectedSearchParam('')
              setSearch('')
            }}>
            <IconSet icon='fas fa-ban' color='light' />
          </Button>
        </div>
      </div>
      <div className='d-flex'>
        <div>
          <NotesTypeFilter filterPage={filterPage} setFilterPage={setFilterPage}></NotesTypeFilter>
        </div>
        <Table bordered hover>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={5}>
                  <div className='d-flex justify-content-center' style={{ marginTop: '4rem', marginBottom: '8rem' }}>
                    <Loader type='ball-pulse' color='blue' />
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {notesData?.data?.length > 0 ? (
                  <>
                    {notesData?.data.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td style={{ width: '2rem' }}>
                            <input
                              type='checkbox'
                              checked={checkUserSelected(selectedLockerUUID, item?.id)}
                              onChange={(e) => handleNotesSelection(e.target.checked, item)}></input>
                          </td>
                          <td>
                            <div className='mb-4'>
                              <div style={{ color: primary_color }}>Title</div>
                              <div>{item?.name}</div>
                            </div>
                            <div className='mb-4'>
                              <div style={{ color: primary_color }}>Notes Type</div>
                              <div>{item?.note_type?.name}</div>
                            </div>
                            <div className='mb-4'>
                              <div style={{ color: primary_color }}>Details</div>
                              <div>{item?.note}</div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </>
                ) : (
                  <div className='d-flex align-items-center justify-content-center'>
                    <img src='not_found.jpg' style={{ width: '20rem' }}></img>
                  </div>
                )}
              </>
            )}
          </tbody>
        </Table>
      </div>

      <div className='d-flex justify-content-center'>
        <Pagination aria-label='Page navigation example' style={{ flexWrap: 'wrap' }}>
          {notesData?.links?.map((item, key) => {
            return (
              <PaginationItem active={item.active} disabled={item.url === null} key={key}>
                <PaginationLink
                  first
                  onClick={() => {
                    console.log('pagination:::::::', item)
                    if (item?.label?.toLowerCase()?.includes('previous')) {
                      setPage(parseInt(notesData?.data?.current_page) - 1)
                    } else if (item?.label?.toLowerCase()?.includes('next')) {
                      setPage(parseInt(notesData?.data?.current_page) + 1)
                    } else {
                      setPage(parseInt(item?.label))
                    }
                  }}>
                  {item.label.replace('&laquo;', '').replace('&raquo;', '')}
                </PaginationLink>
              </PaginationItem>
            )
          })}
        </Pagination>
      </div>
    </div>
  )
}

export default NotesTable
