import moment from 'moment'

import {
  checkIsDataUpdated,
  checkIsDataUpdatedInLog,
  checkIsFieldHide,
  getFieldsAndLabelByTab,
  getTabNameForLogs,
  getValueByFieldTypeNid,
} from '../../../../Pages/Logs/logHelper'
import { primary_color } from '../../../../Helper/uiHelper'

const ShowDifference = ({ data, original_data, updated_data, onExpand, difference, parentPageName, fields }) => {
  console.log('updated log:::::::::', original_data, updated_data)
  return (
    <div>
      <div className='permission-table'>
        <div className='mb-2 cursor-pointer' onClick={() => onExpand()}>
          <i class='fa-sharp fa-solid fa-arrow-left  m-3'></i>
          Go Back
        </div>
        <div className={`permission-header`} style={{ color: primary_color }}>
          Details
        </div>
        <div className='p-4'>
          {fields?.map((item) => {
            if (checkIsFieldHide(item?.field_id)) return
            return (
              <div className='d-flex mb-2 align-items-center' style={{ width: '100%' }}>
                <div className='font-weight-bold mb-2' style={{ width: '40%' }}>
                  {item?.label}:
                </div>
                <div style={{ width: '30%' }}>{getValueByFieldTypeNid(updated_data, item?.type, item?.field_id)}</div>
                {checkIsDataUpdated(original_data, updated_data, item?.field_id) &&
                  getValueByFieldTypeNid(original_data, item?.type, item?.field_id) && (
                    <div
                      style={{ width: '30%' }}
                      className={`${checkIsDataUpdated(original_data, updated_data, item?.field_id) ? 'p-2 bg-danger text-light' : ''}`}>
                      {getValueByFieldTypeNid(original_data, item?.type, item?.field_id)}
                    </div>
                  )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const UpdateLog = ({ data, onExpand, expandedId, parentPageName }) => {
  let updated_data = data?.updated_data && JSON.parse(data?.updated_data)
  let original_data = data?.original_data && JSON.parse(data?.original_data)
  let tab_name = getTabNameForLogs(parentPageName, data?.tab_name)
  const fields = getFieldsAndLabelByTab(parentPageName, data?.tab_name)
  console.log('update log::::::::', data, parentPageName)
  if (expandedId) {
    return <ShowDifference data={data} updated_data={updated_data} original_data={original_data} fields={fields} onExpand={onExpand}></ShowDifference>
  }
  // if (!checkIsDataUpdatedInLog(original_data, updated_data, fields)) return
  return (
    <div className='d-flex align-items-center p-3' style={{ borderBottom: '1px solid #e0e0e0' }}>
      <div>
        <i
          class='fa-solid fa-arrow-up-right-from-square mr-2 cursor-pointer'
          style={{ color: primary_color }}
          onClick={() => {
            onExpand(data?.id)
          }}></i>
      </div>
      <div style={{ flex: 1 }}>
        <span className='font-weight-bold'>{data?.updated_by}</span>
        {` has updated data in `}
        <span className='font-weight-bold'>{tab_name}</span>
      </div>
      {data?.updated_at && <div>{moment(data?.updated_at).format('MMM D  YYYY, hh:mm')}</div>}
    </div>
  )
}

export default UpdateLog
