import { Button, Row, Col } from 'reactstrap'
import { useState } from 'react'
import { AvField, AvForm } from 'availity-reactstrap-validation'

import { primary_color } from '../../../../Helper/uiHelper'
import { request } from '../../../../Redux/Sagas/requests/api'
import { isSuccessResponse } from '../../../../helper'

const AssignForm = ({ defaultValue, onCloseForm, refetch }) => {
  const [loading, setLoading] = useState(false)

  const onSubmit = (values) => {
    setLoading(true)
    request('assign-voucher', 'POST', { id: defaultValue?.id, ...values }).then((res) => {
      if (isSuccessResponse(res, 'Voucher assigned successfully!')) {
        setLoading(false)
        onCloseForm()
        refetch()
      }
    })
  }
  return (
    <div className='m-5'>
      <div className='d-flex mb-5 mt-5 align-items-center'>
        <Button variant='dark' className='mr-4' onClick={() => onCloseForm()}>
          <i class='fa-regular fa-arrow-left'></i>
        </Button>
        <div className='font-weight-bold body1' style={{ color: primary_color }}>
          Send
        </div>
      </div>
      <AvForm
        onValidSubmit={(e, values) => {
          onSubmit(values)
        }}>
        <Row>
          <Col md={6}>
            <AvField type='text' name='name' label='Name'></AvField>
          </Col>
          <Col md={6}>
            <AvField type='text' name='email' label='Email'></AvField>
          </Col>
          <Col md={12}>
            <AvField type='textarea' name='email' label='Email'></AvField>
          </Col>
        </Row>
      </AvForm>
      <div className='d-flex justify-content-end mt-5'>
        {' '}
        <button
          className='btn rounded-pill mt-2 text-danger border-danger mr-3'
          type='button'
          onClick={(e) => {
            onCloseForm()
            e.preventDefault()
            e.stopPropagation()
          }}
          style={{ fontWeight: 700, width: '12rem' }}>
          Cancel
        </button>
        <button
          className='btn text-white rounded-pill mt-2'
          disabled={!!loading}
          type='submit'
          style={{ background: primary_color, fontWeight: 700, width: '14rem' }}>
          {loading ? 'Saving...' : 'Save'}
        </button>
      </div>
    </div>
  )
}

export default AssignForm
