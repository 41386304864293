import { AvField, AvForm, AvRadio, AvRadioGroup } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import { IoMdReturnLeft } from 'react-icons/io'
import { primary_color } from '../../../Helper/uiHelper'
import { Button } from 'reactstrap'
import { DUMMY_LOCKER } from '../../Form/Fields/dummyLocker'
import LockerListing from './lockerListing'
import { request } from '../../../Redux/Sagas/requests/api'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import RestoreLocker from './restoreLocker'
import ArchieveArea from './archiveArea'
import MoveWithinBlock from './moveWithinBlock'
import MoveOutsideBlock from './moveOutsideBlock'

export const displayVertically = (arr, rowCount) => {
  const output = arr?.reduce((rows, item, index) => {
    const rowIndex = index % rowCount
    rows[rowIndex] = rows[rowIndex] || []
    rows[rowIndex].push(item)
    return rows
  }, [])

  return output
}

const ACTIONS = [
  {
    title: 'Number of Lockers',
    value: 'number_of_locker',
  },
  {
    title: 'Empty/Blank',
    value: 'empty',
  },
  {
    title: 'Delete',
    value: 'delete',
  },
  {
    title: 'Renumber',
    value: 'renumber',
  },
  {
    title: 'Reorder',
    value: 'reorder',
  },
  {
    title: 'Move Within Block',
    value: 'move_within_block',
  },
  {
    title: 'Move Outside Block',
    value: 'move_outside_block',
  },
]

const ManageLocker = ({ setBlocketails, blockDetails, form, isPlannedBlock }) => {
  const dispatch = useDispatch()
  const [action, setAction] = useState()
  const [lockerDimension, setLockerDimension] = useState()
  const [selectedLockerIds, setSelectedLockerIds] = useState([])
  const [selectedLockers, setSelectedLockers] = useState([])
  const [isRestoringLocker, setIsRestoringLocker] = useState(false)

  const [lockers, setLockers] = useState()
  const [archievedLocker, setArchievedLocker] = useState([])
  console.log('manage locker:::::', lockers, lockerDimension)

  const [loader, setLoader] = useState({
    number: false,
    empty: false,
    renumber: false,
    delete: false,
  })

  useEffect(() => {
    setLockerDimension({
      column: Math.ceil(blockDetails?.lockers?.length / blockDetails?.lockerHeight) || 0,
      row: blockDetails?.lockerHeight || 0,
    })

    setLockers(displayVertically(blockDetails?.lockers, blockDetails?.lockerHeight))
  }, [blockDetails])

  const onChangeLockerDimension = () => {
    let payload = {
      item_date_time_id: blockDetails?.item_date_time_id,
      domain_id: blockDetails?.domain_id,
      location_id: blockDetails?.id,
      rows: parseInt(lockerDimension?.row),
      columns: parseInt(lockerDimension?.column),
    }
    setLoader({
      ...loader,
      number: true,
    })
    request('store-planned-locker', 'POST', payload).then((res) => {
      if (res.status === 200 || res.status === 202) {
        dispatch(setNotification({ type: 'success', message: 'Locker created successfully!' }))
        request('get-location-data', 'POST', { location_id: blockDetails?.id }).then((res) => {
          if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
            setBlocketails(res?.data)
          }
          setLoader({
            ...loader,
            number: false,
          })
        })
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message || 'Something went wrong!' }))
        setLoader({
          ...loader,
          number: false,
        })
      }
    })
    // const data = displayVertically(DUMMY_LOCKER?.data, parseInt(lockerDimension?.row))
    // setLockers(data)
  }

  const onEmptyLocker = () => {
    if (selectedLockerIds?.length === 0) {
      dispatch(setNotification({ type: 'error', message: 'Please select atleast one locker!' }))
      return
    }
    let payload = selectedLockerIds
    setLoader({
      ...loader,
      empty: true,
    })
    request('empty-locker', 'POST', payload).then((res) => {
      if (res.status === 200 || res.status === 202) {
        dispatch(setNotification({ type: 'success', message: 'Locker empty successfully!' }))
        request('get-location-data', 'POST', { location_id: blockDetails?.id }).then((res) => {
          if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
            setBlocketails(res?.data)
          }
          setLoader({
            ...loader,
            empty: false,
          })
        })
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message || 'Something went wrong!' }))
        setLoader({
          ...loader,
          empty: false,
        })
      }
    })
    setSelectedLockerIds([])
    setSelectedLockers([])
  }

  const onDeleteLocker = () => {
    if (selectedLockerIds?.length === 0) {
      dispatch(setNotification({ type: 'error', message: 'Please select atleast one locker!' }))
      return
    }
    let payload = {
      items: selectedLockerIds?.map((item) => {
        return {
          item_id: item,
        }
      }),
    }
    setLoader({
      ...loader,
      delete: true,
    })
    request('delete-locker', 'POST', payload).then((res) => {
      if (res.status === 200 || res.status === 202) {
        dispatch(setNotification({ type: 'success', message: 'Locker empty successfully!' }))
        request('get-location-data', 'POST', { location_id: blockDetails?.id }).then((res) => {
          if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
            setBlocketails(res?.data)
          }
          setLoader({
            ...loader,
            delete: false,
          })
        })
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message || 'Something went wrong!' }))
        setLoader({
          ...loader,
          delete: false,
        })
      }
    })
    setSelectedLockerIds([])
    setSelectedLockers([])
  }

  const onDeleteEmptyLocker = (id) => {
    let payload = {
      items: [
        {
          item_id: id,
        },
      ],
    }
    request('delete-locker', 'POST', payload).then((res) => {
      if (res.status === 200 || res.status === 202) {
        dispatch(setNotification({ type: 'success', message: 'Locker empty successfully!' }))
        request('get-location-data', 'POST', { location_id: blockDetails?.id }).then((res) => {
          if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
            setBlocketails(res?.data)
          }
          setLoader({
            ...loader,
            delete: false,
          })
        })
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message || 'Something went wrong!' }))
        setLoader({
          ...loader,
          delete: false,
        })
      }
    })
    setSelectedLockerIds([])
    setSelectedLockers([])
  }
  const onRenumberLocker = () => {
    if (selectedLockerIds?.length === 0) {
      dispatch(setNotification({ type: 'error', message: 'Please select atleast one locker!' }))
      return
    }
    let payload = {
      items: selectedLockers?.map((item) => {
        return {
          item_id: item?.id,
          name: item?.name,
        }
      }),
    }
    setLoader({
      ...loader,
      renumber: true,
    })
    request('renumber-locker', 'POST', payload).then((res) => {
      if (res.status === 200 || res.status === 202) {
        dispatch(setNotification({ type: 'success', message: 'Locker empty successfully!' }))
        request('get-location-data', 'POST', { location_id: blockDetails?.id }).then((res) => {
          if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
            setBlocketails(res?.data)
          }
          setLoader({
            ...loader,
            renumber: false,
          })
        })
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message || 'Something went wrong!' }))
        setLoader({
          ...loader,
          renumber: false,
        })
      }
    })
    setSelectedLockerIds([])
    setSelectedLockers([])
  }
  const onReOrder = () => {
    if (archievedLocker?.length > 0) {
      dispatch(setNotification({ type: 'error', message: 'Please place all archieved locker' }))
      return
    }
    const result = []
    const maxCols = Math.max(...lockers.map((row) => row.length))

    for (let col = 0; col < maxCols; col++) {
      for (let row = 0; row < lockers.length; row++) {
        if (lockers[row][col] !== undefined) {
          result.push(lockers[row][col])
        }
      }
    }
    const items = result?.map((item, index) => {
      return {
        ...item,
        order: index + 1,
      }
    })
    setLoader({
      ...loader,
      reorder: true,
    })
    request('reorder', 'POST', { items, location_id: blockDetails?.id }).then((res) => {
      if (res.status === 200 || res.status === 202) {
        dispatch(setNotification({ type: 'success', message: 'Locker empty successfully!' }))
        request('get-location-data', 'POST', { location_id: blockDetails?.id }).then((res) => {
          if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
            setBlocketails(res?.data)
          }
          setLoader({
            ...loader,
            reorder: false,
          })
        })
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message || 'Something went wrong!' }))
        setLoader({
          ...loader,
          reorder: false,
        })
      }
    })
  }

  let isLockerSelectable = true
  let isSelectSingleLocker = false
  let isDraggable = false
  if (action === 'number_of_locker' || !action || action === 'reorder' || action === 'move_within_block') {
    isLockerSelectable = false
  }
  if (action === 'renumber' || action === 'move_outside_block') {
    isSelectSingleLocker = true
  }
  if (action === 'reorder') {
    isDraggable = true
  }
  if (isRestoringLocker)
    return <RestoreLocker setIsRestoringLocker={setIsRestoringLocker} blockDetails={blockDetails} setBlocketails={setBlocketails}></RestoreLocker>
  return (
    <div className='mt-4'>
      <div className='mb-4'>
        <Button color='danger px-4' disabled={blockDetails?.deleted_lockers?.length === 0} onClick={() => setIsRestoringLocker(true)}>
          <i class='fa-solid fa-recycle mr-3'></i>Bin
        </Button>
      </div>
      {ACTIONS?.map((item) => {
        return (
          <div>
            <div className='mb-3'>
              <input
                type='radio'
                className='subtitle1'
                id={item?.value}
                name={item?.value}
                value={item?.value}
                checked={action === item?.value}
                onChange={(e) => {
                  if (e.target.checked) {
                    setAction(item?.value)
                    setSelectedLockerIds([])
                    setSelectedLockers([])
                  }
                }}
              />
               {' '}
              <label className='subtitle1' for={item?.value}>
                {item?.title}
              </label>
              <br></br>
            </div>
            {action === 'number_of_locker' && action === item?.value && (
              <div className='d-flex p-3'>
                <div className='d-flex mr-4 align-items-center'>
                  <i class='fa-sharp-duotone fa-regular fa-arrow-up mb-3 mr-4' style={{ color: primary_color }}></i>
                  <AvForm>
                    <AvField
                      type='number'
                      name='row'
                      style={{ width: '4rem' }}
                      value={lockerDimension.row}
                      onChange={(e) => {
                        setLockerDimension({
                          ...lockerDimension,
                          row: e.target.value,
                        })
                        setSelectedLockerIds([])
                        setSelectedLockers([])
                      }}></AvField>
                  </AvForm>
                </div>
                <div className='d-flex mr-4 align-items-center'>
                  <i class='fa-sharp-duotone fa-regular fa-arrow-right mb-3 mr-4' style={{ color: primary_color }}></i>
                  <AvForm>
                    <AvField
                      type='number'
                      name='column'
                      value={lockerDimension.column}
                      style={{ width: '4rem' }}
                      onChange={(e) => {
                        setLockerDimension({
                          ...lockerDimension,
                          column: e.target.value,
                        })
                      }}></AvField>
                  </AvForm>
                </div>
                <div className='d-flex'>
                  <Button
                    className='mb-3'
                    color='info'
                    disabled={!lockerDimension?.row || !lockerDimension?.column}
                    onClick={() => {
                      onChangeLockerDimension()
                    }}>
                    {loader?.number ? 'Submitting...' : 'Submit'}
                  </Button>
                </div>
              </div>
            )}
            {action === 'empty' && action === item?.value && (
              <button
                className='btn text-white mt-2 mb-4 ml-4'
                // disabled={!!loading}
                type='submit'
                onClick={() => {
                  onEmptyLocker()
                }}
                style={{ background: primary_color, fontWeight: 700 }}>
                {loader.empty ? 'Submitting...' : 'Submit'}
              </button>
            )}
            {action === 'renumber' && action === item?.value && (
              <div className='d-flex align-items-center'>
                <AvForm>
                  <AvField
                    type='text'
                    name='column'
                    value={selectedLockers?.[0]?.name}
                    style={{ width: '10rem' }}
                    onChange={(e) => {
                      const newSelectedLockers = [...selectedLockers]
                      newSelectedLockers[0]['name'] = e.target.value
                    }}></AvField>
                </AvForm>
                <button
                  className='btn text-white mt-2 mb-4 ml-4'
                  disabled={selectedLockerIds?.length === 0}
                  type='submit'
                  onClick={() => {
                    onRenumberLocker()
                  }}
                  style={{ background: primary_color, fontWeight: 700 }}>
                  {loader.renumber ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            )}
            {action === 'delete' && action === item?.value && (
              <button
                className='btn text-white mt-2 mb-4 ml-4'
                // disabled={!!loading}
                type='submit'
                onClick={() => {
                  onDeleteLocker()
                }}
                style={{ background: primary_color, fontWeight: 700 }}>
                {loader.delete ? 'Submitting...' : 'Submit'}
              </button>
            )}
            {action === 'reorder' && action === item?.value && (
              <button
                className='btn text-white mt-2 mb-4 ml-4'
                // disabled={!!loading}
                type='submit'
                onClick={() => {
                  onReOrder()
                }}
                style={{ background: primary_color, fontWeight: 700 }}>
                {loader.reorder ? 'Saving...' : 'Save'}
              </button>
            )}
            {action === 'move_within_block' && action === item?.value && (
              <MoveWithinBlock blockDetails={blockDetails} setBlocketails={setBlocketails}></MoveWithinBlock>
            )}
            {action === 'move_outside_block' && action === item?.value && (
              <MoveOutsideBlock
                blockDetails={blockDetails}
                setBlocketails={setBlocketails}
                setSelectedLockerIds={setSelectedLockerIds}
                selectedLockerIds={selectedLockerIds}
                setSelectedLockers={setSelectedLockers}
                selectedLockers={selectedLockers}
                isPlannedBlock={isPlannedBlock}></MoveOutsideBlock>
            )}
          </div>
        )
      })}
      {action === 'reorder' && (
        <ArchieveArea
          lockers={lockers}
          setLockers={setLockers}
          blockDetails={blockDetails}
          archievedLocker={archievedLocker}
          setArchievedLocker={setArchievedLocker}></ArchieveArea>
      )}

      {/* {lockers?.length > 0 && (
        <div className='mt-5'>
          <LockerListing
            lockers={lockers}
            isLockerSelectable={isLockerSelectable}
            setSelectedLockerIds={setSelectedLockerIds}
            selectedLockerIds={selectedLockerIds}
            setSelectedLockers={setSelectedLockers}
            selectedLockers={selectedLockers}
            isSelectSingleLocker={isSelectSingleLocker}></LockerListing>
        </div>
      )} */}
      {lockers?.length > 0 && (
        <div className='mt-5'>
          <LockerListing
            lockers={lockers}
            isLockerSelectable={isLockerSelectable}
            setSelectedLockerIds={setSelectedLockerIds}
            selectedLockerIds={selectedLockerIds}
            setSelectedLockers={setSelectedLockers}
            selectedLockers={selectedLockers}
            isSelectSingleLocker={isSelectSingleLocker}
            isDraggable={isDraggable}
            archievedLocker={archievedLocker}
            setArchievedLocker={setArchievedLocker}
            setLockers={setLockers}
            onDeleteEmptyLocker={onDeleteEmptyLocker}></LockerListing>
        </div>
      )}
    </div>
  )
}

export default ManageLocker
