const TransactionStatus = ({ inputValue }) => {
  if (inputValue === 'Approved') {
    return <span class='badge badge-success'>Approved</span>
  }else if (inputValue === 'Confirm') {
    return <span class='badge badge-success'>Confirm</span>
  } else if (inputValue === 'Decline') {
    return <span class='badge badge-danger'>Decline</span>
  } else if (inputValue === 'Cancel') {
    return <span class='badge badge-danger'>Cancel</span>
  } else if (inputValue === 'Waiting  For Approval' || inputValue === 'Waiting For Approval') {
    return <span class='badge badge-warning'>Waiting For Approval</span>
  } else if (inputValue === 'Accept') {
    return <span class='badge badge-primary'>Accept</span>
  } else if (inputValue === 'Pending') {
    return <span class='badge badge-warning'>Pending</span>
  } else if (inputValue === '0000') {
    return <span class='badge badge-success'>Confirmed</span>
  } else if (inputValue === '2021') {
    return <span class='badge badge-warning'>Processing</span>
  } else if (inputValue === '3141' || inputValue === '422') {
    return <span class='badge badge-danger'>Failed</span>
  }
}

export default TransactionStatus
