import { useSelector } from 'react-redux'
import Loader from 'react-loaders'
import { useState } from 'react'
import { useFetchLogsByTable } from '../../../../Queries/Logs/useFetchLogsByTable'
import CreateVoucherLog from './createVoucherLog'
import CreateBlockLog from './createBlockLog'
import CreateLockers from './createLockersLog'
import DeleteLockerLog from './deleteLockerLog'
import RestoreLockerLog from './restoreLockerLog'
import RenumberLocker from './reNumberLocker'
import LockerMaintananceLog from './lockerMaintainanceLog'
import UpdateLog from './updateLogs'
import MoveWithinBlock from './moveWithinBlock'

const renderLog = (type, data, onExpand, expandedId, parentPageName) => {
  
  switch (type) {
    case 'create_voucher':
      return <CreateVoucherLog data={data} onExpand={onExpand} expandedId={expandedId}></CreateVoucherLog>
    case 'create_block':
      return <CreateBlockLog data={data} onExpand={onExpand} expandedId={expandedId}></CreateBlockLog>
    case 'create_lockers':
      return <CreateLockers data={data} onExpand={onExpand} expandedId={expandedId}></CreateLockers>
    case 'locker_delete':
      return <DeleteLockerLog data={data} onExpand={onExpand} expandedId={expandedId}></DeleteLockerLog>
    case 'locker_restore':
      return <RestoreLockerLog data={data} onExpand={onExpand} expandedId={expandedId}></RestoreLockerLog>
    case 'locker_renumber':
      return <RenumberLocker data={data} onExpand={onExpand} expandedId={expandedId}></RenumberLocker>
    case 'locker_maintanance':
      return <LockerMaintananceLog data={data} onExpand={onExpand} expandedId={expandedId}></LockerMaintananceLog>
    case 'move_same_block':
      return <MoveWithinBlock data={data} onExpand={onExpand} expandedId={expandedId}></MoveWithinBlock>
    case 'update':
      return <UpdateLog data={data} onExpand={onExpand} expandedId={expandedId} parentPageName={parentPageName}></UpdateLog>
    default:
      return
  }
}

const DomainLogs = ({ parentPageName }) => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  let role_id = currentRow?.[currentRow?.length - 1]?.uuid
  const { data, isLoading } = useFetchLogsByTable(role_id, 'domains')

  const [expandedId, setExpandedId] = useState()

  const onExpand = (id) => {
    console.log('expanded id::::::', id)
    setExpandedId(id)
  }

  if (!isLoading && data?.data?.length === 0)
    return (
      <div style={{ marginTop: '3rem' }} className='text-center font-weight-bold'>
        Logs not available!
      </div>
    )
  if (data?.data?.length !== 0 && isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '10rem', alignItems: 'center' }}>
        <Loader type='ball-pulse' color='blue' />
      </div>
    )

  return (
    <div style={{ maxHeight: '30rem', overflow: 'auto' }}>
      {data?.data?.map((item, index) => {
        if (!expandedId) {
          return (
            <div key={index} className='p-1'>
              {renderLog(item?.type, item, onExpand, null, parentPageName)}
            </div>
          )
        } else {
          if (expandedId === item?.id) {
            return (
              <div key={index} className='p-1'>
                {renderLog(item?.type, item, onExpand, expandedId, parentPageName)}
              </div>
            )
          } else {
            return <></>
          }
        }
      })}
    </div>
  )
}

export default DomainLogs
