import { AvForm } from 'availity-reactstrap-validation'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card } from 'reactstrap'
import RenderFields from '../../../CMSComponent/Forms/renderFields'
import { getLocalStorageItem } from '../../../Helper/cookieHelper'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { request } from '../../../Redux/Sagas/requests/api'
import FieldsLayout from './fieldsLayout'
import { FaPlus } from 'react-icons/fa'
import { primary_color } from '../../../Helper/uiHelper'
import { _validateFormData } from '../../../Helper/publicPageHelper'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import CMSWidgetModal from '../../CMSWidget/cmsWidgetModal'
import CMSHeroSearch from '../../CMSWidget/HeroSearch/cmsHeroSearch'
import CMSFormEdit from '../../CMSWidget/Form/cmsFormEdit'

const action_button = [
  {
    title: 'Cancel',
    component_call_action: {
      action: 'main_page',
    },
    outline: true,
    color: 'danger',
  },
  {
    title: 'Continue',
    component_call_action: {
      action: 'open_child_page',
      page: 'locker_payment',
    },
    color: 'info',
    icon: 'fa-solid fa-arrow-right',
  },
]

const changeNcontinueButton = {
    title: 'Change & Continue',
    component_call_action: {
      action: 'open_child_page',
      page: 'locker_payment',
    },
    color: 'info',
    icon: 'fa-solid fa-arrow-right',
  }

const AddForm = ({
  onSubmitForm,
  validationError,
  setValidationError,
  form,
  onValueChange,
  submittedFormData,
  formData,
  updateFormData,
  formIndex,
  onRemoveFormData,
  parentData,
  formsData,
  unitData,
  setUnitData,
  setFormData,
}) => {
  // const [formValue, updateFormValue] = useState({
  //   category_id: '',
  //   item_date_time_id: '',
  //   location_id: '',
  // })
  // useEffect(() => {
  //   updateFormData(formValue, formIndex)
  // }, [formValue])

  const updateFormValue = (values) => {
    updateFormData(values, formIndex)
  }

  useEffect(() => {
    // updateFormValue(formData)
  }, [JSON.stringify(formData)])

  const content = [
    {
      layout: '[{"column":[{"col":"12"},{"col":"6"},{"col":"12"}]}]',
    },
  ]

  return (
    <AvForm
    // onValidSubmit={(e, values) => onSubmitForm(values)}
    >
      <div className='d-flex justify-content-between align-items-center'>
        <div className='h4' style={{ fontWeight: '800', color: primary_color }}>
          Locker {formIndex + 1}
        </div>
        <Button
          outline
          onClick={() => onRemoveFormData(formIndex)}
          color='danger'
          className='rounded-pill subtitle1'
          style={{ paddingInline: '2rem' }}
          variant='outline-primary' // Change the variant to the desired color
        >
          Remove Student
        </Button>
      </div>
      <FieldsLayout
        validationError={validationError}
        setValidationError={setValidationError}
        form={form}
        formIndex={formIndex}
        content={content}
        formFieldValue={formData}
        setFormFieldValue={updateFormValue}
        parentData={parentData}
        allFormsData={formsData}
        unitData={unitData}
        setUnitData={setUnitData}
        setFormData={setFormData}
      />
    </AvForm>
  )
}

const RenderSingleForm = () => {
  return <div>single form</div>
}

const RenderRepeatableForm = ({
  form,
  formsData,
  setFormData,
  parentData,
  data: widgetData,
  pageData,
  setActivePage,
  setParentData,
  onGoBackToHomePage,
  unitData,
  setUnitData,
  widget_uuid,
}) => {
  const dispatch = useDispatch()
  
  const isDesktop = useMediaQuery('(min-width:768px)')

  const sectionRef = useRef(null)

  const editMode = useSelector((state) => state.CMS.editMode)

  const [modalPosition, setModalPosition] = useState()

  const handleSectionClick = () => {
    if (!editMode) return
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPosition({
        //  top: rect.top + window.scrollY - 50,
        top: rect.height + 150,

        //  left: `${rect.left + window.scrollX + rect.width / 2 - 10}px` , // Position below the parent element
        //  left: rect.left + window.scrollX, // Align left edge with parent element
        width: '60vw',
      })
    }
  }

  // const [formsData, setFormData] = useState([
  //   {
  //     category_id: '',
  //     item_date_time_id: '',
  //     location_id: '',
  //   },
  // ])

  const [unitApiCalled, setUnitApiCalled] = useState(false)
  const [validationError, setValidationError] = useState(false)
  const [parentDetails, setParentDetails] = useState()
  // const [retrieve_uuid, setRetrieveUuid] = useState('1d028213-a53e-40c8-8f0c-89e29d3d9125')
  let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer'))
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const parent_id = searchParams.get('parent_id')
  const isEditing = searchParams.get('edit_booking')
  const publicUser = useSelector((state) => state.User.publicUser)

  let current_storage_timer = storage_timer?.[parent_id]
  useEffect(() => {
    const fields = form?.component_call_fields?.map((item) => item?.field_id)

    request('retrieve-data-by-table', 'POST', {
      source_table: 'domains',
      row_id: parent_id,
    }).then((res) => {
      if (res?.data) {
        setParentDetails(res?.data?.data)
      }
    })

    if (!!searchParams.get('retrieve_booking')) {
      request('retrieve-reservation-session', 'POST', { parent_id: searchParams.get('parent_id') }).then((res) => {
        if (res?.data?.data && res?.data?.data?.units) {
          let items = JSON.parse(JSON.stringify(res?.data?.data.units))
          setFormData(items)
          setUnitData(items)
        }
      })
    } else if (!!searchParams.get('old_booking')) {
      request('get-old-booking', 'POST', { parent_id: searchParams.get('parent_id') }).then((res) => {
        if (res?.data && res?.data?.units) {
          let items = JSON.parse(JSON.stringify(res?.data?.units))
          const itemsToUpdate = items?.map((item) => {
            let obj = { ...item }
            obj['item_id'] = null
            obj['item_id_label'] = null
            obj['questions'] = item?.questions?.map((q) => {
              return {
                ...q,
                answer: null,
              }
            })
            return obj
          })
          console.log('items to update::::::::', itemsToUpdate)
          setFormData(itemsToUpdate)
          setUnitData(itemsToUpdate)
        }
      })
    } else if (!!searchParams.get('edit_booking')) {
      request('get-booking-by-payment-id', 'POST', { payment_id: searchParams.get('payment_id') && parseInt(searchParams.get('payment_id')) }).then(
        (res) => {
          if (res?.data && res?.data?.units) {
            let items = JSON.parse(JSON.stringify(res?.data?.units))
            setFormData(items)
            setUnitData(items)
          }
        }
      )
    } else {
      if (!current_storage_timer?.session_uuid) return
      request('get-unit', 'POST', { uuid: current_storage_timer?.session_uuid, fields }).then((res) => {
        if (res.status === 200 || res?.status === 202) {
          if (res?.data?.unitData?.length > 0) {
            setFormData(res?.data?.unitData)
            setUnitData(res?.data?.unitData)
          }
        }
      })
    }
  }, [parent_id])

  const updateFormData = (data, index) => {
    setFormData((prevFormsData) => {
      return prevFormsData.map((item, i) => {
        if (index === i) {
          return data
        } else {
          return item
        }
      })
    })
  }

  const onAddNewForm = () => {
    const newFormsData = [...formsData]
    newFormsData?.push({
      category_id: '',
      item_date_time_id: '',
      location_id: '',
    })
    setFormData(newFormsData)
  }

  const onRemoveFormData = (index) => {
    const newFormsData = []
    formsData?.map((item, i) => {
      if (index !== i) {
        newFormsData?.push(item)
      }
    })
    setFormData(newFormsData)
  }
  if (formsData && formsData.length) {
    const items = JSON.parse(JSON.stringify(formsData))
    items.forEach((e) => delete e['uuid'])
  }

  const onClickActionButton = (e, button) => {
    e.preventDefault()
    const type = button?.component_call_action?.action
    if (type === 'open_child_page' && !_validateFormData(formsData)) return setValidationError(true)
    let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer'))
    let current_storage_timer = storage_timer?.[parentData?.id]
    switch (type) {
      case 'open_child_page':
        const new_page = pageData?.child_pages?.find((item) => item?.name === button?.component_call_action?.page)
        const item_ids = []
        formsData?.forEach((data) => {
          if (!item_ids?.includes(data?.item_id)) {
            item_ids?.push(data?.item_id)
          }
        })

        if (item_ids?.length === 0) {
          dispatch(setNotification({ type: 'error', message: 'Please select atleast one locker!' }))
          return
        }
        if (item_ids?.length !== formsData?.length) {
          dispatch(setNotification({ type: 'error', message: 'Please select unique locker!' }))
          return
        }
        let items = JSON.parse(JSON.stringify(formsData))
        if (formsData && formsData.length) {
          items = formsData.map((e) => {
            if (e && e.questions && e.questions.length) {
              const object = {}
              e?.questions?.map((q) => (object[q.uuid] = q.answer))
              return {
                ...e,
                questions: object,
              }
            } else return e
          })
        }

        request('reserve-item-booking', 'POST', {
          items,
          session_key: current_storage_timer?.session_id,
          domain_id: searchParams.get('parent_id'),
          payment_id: searchParams.get('payment_id') && parseInt(searchParams.get('payment_id')),
        }).then((res) => {
          if (res.status === 200 || res.status === 202) {
            if (new_page) {
              // setActivePage(new_page)
              const searchParams = new URLSearchParams(location.search)
              searchParams.set('page_id', new_page?.uuid)
              history.push(`?${searchParams.toString()}`)
            }
          } else {
            dispatch(setNotification({ type: 'error', message: res?.data?.message }))
          }
        })

        return
      default:
        // setActivePage(pageData)
        // setParentData()
        onGoBackToHomePage()
    }
  }

  // const _retrievePrevBooking = () => {
  //   request('get-retrieve-booking', 'POST', { uuid: retrieve_uuid }).then((res) => {
  //     if (res?.data && res?.data.bookingReservation && res?.data.bookingReservation.units) {
  //       const items = JSON.parse(JSON.stringify(res?.data.bookingReservation.units))
  //       if (res?.data.bookingReservation.units.length) {
  //         items.forEach((e) => delete e['uuid'])
  //       }

  //       setFormData(items)
  //       setUnitData(items)
  //     }
  //   })
  // }

  const onCloseModal = () => {
    let element = sectionRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPosition(null)
  }

  const default_image = 'https://upload.wikimedia.org/wikipedia/commons/7/7a/Delhi_Public_School%2C_Ranchi.JPG'

  console.log('formdata::::::::::::', formsData)
  return (
    <div className='col-md-9 ml-auto mr-auto mb-5'>
      {parentDetails && (
        <div className={`mb-5 ${isDesktop ? '' : 'mt-5'}`}>
          <div className={`d-flex align-items-center `}>
            <div>
              {parentDetails?.uploads?.[0]?.upload_files?.[0]?.file_name ? (
                <img
                  src={
                    parentDetails?.uploads?.[0]?.upload_files?.[0]?.file_name
                      ? parentDetails?.uploads?.[0]?.upload_files?.[0]?.file_name
                      : default_image
                  }
                  width='60'
                  height='60'
                  className='me-3'
                  style={{ borderRadius: '50%', objectFit: 'cover', border: '2px solid #495057' }}
                />
              ) : (
                <img
                  src={default_image}
                  width='60'
                  height='60'
                  className='me-3'
                  style={{ borderRadius: '50%', objectFit: 'cover', border: '2px solid #495057' }}
                />
              )}
            </div>
            <div style={{ flex: 1 }} className='ml-4'>
              <div className='font-weight-bold body1'>{parentDetails?.domain_name}</div>
              {/* <div className='subtitle1' style={{ fontWeight: '400' }}>
                {parentDetails?.note}
              </div> */}
            </div>
          </div>
          {/* {retrieve_uuid && publicUser?.email ? (
            <div className='mt-4'>
              <button
                className={`btn text-white ${isDesktop ? 'pl-4 pr-4' : 'pl-2 pr-2'}`}
                style={{ borderRadius: '20px', background: primary_color, fontSize: '0.75rem' }}
                onClick={() => {
                  _retrievePrevBooking()
                }}>
                Retrieve Your Previous Booking
              </button>
            </div>
          ) : (
            <></>
          )} */}
        </div>
      )}
      <form>
        {formsData?.map((data, index) => {
          return (
            <div
              className={`mt-1 ml-auto mr-auto position-relative ${modalPosition && 'active-cms-edit'}`}
              onClick={handleSectionClick}
              ref={sectionRef}>
              <AddForm
                formData={data}
                formIndex={index}
                validationError={validationError}
                setValidationError={setValidationError}
                key={index}
                updateFormData={updateFormData}
                form={form}
                onRemoveFormData={onRemoveFormData}
                parentData={parentData}
                formsData={formsData}
                unitData={unitData}
                setUnitData={setUnitData}
                setFormData={setFormData}
              />
              {modalPosition && (
                <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
                  <CMSFormEdit
                    data={widgetData}
                    toggleModal={() => {
                      onCloseModal()
                    }}
                    widget_uuid={widget_uuid}
                  />
                </CMSWidgetModal>
              )}
              <div style={{ margin: '2rem 10%', width: '80%', borderBottom: index < formsData.length - 1 ? '1px solid' : '' }}></div>
            </div>
          )
        })}
        <div className='d-flex'>
          <Button className='btn rounded-pill  mt-3 mb-3' style={{ background: primary_color, paddingInline: '3rem' }} onClick={() => onAddNewForm()}>
            <span className='d-flex align-items-center subtitle1'>
              <FaPlus className='mr-1' /> ADD NEW STUDENT
            </span>
          </Button>
          {isEditing && (
            <Button
              className='ml-2 btn rounded-pill mt-3 mb-3 subtitle1'
              style={{
                background: primary_color,
                paddingInline: '3rem',
              }}
              type={changeNcontinueButton?.component_call_action?.action === 'open_child_page' ? 'submit' : ''}
              onClick={(e) => {
                onClickActionButton(e, changeNcontinueButton)
              }}>
              {changeNcontinueButton?.title} {changeNcontinueButton?.icon && <i className={changeNcontinueButton?.icon}></i>}
            </Button>
          )}
        </div>

        <div className={`mt-4 d-flex ${isDesktop ? '' : 'mb-4'}`}>
          {!isEditing && action_button?.map((button) => {
            if (button?.outline) {
              return (
                <Button
                  color={button?.color}
                  outline={button?.outline}
                  className='mr-2 btn rounded-pill subtitle1'
                  style={{
                    paddingInline: isDesktop ? '6.5rem' : '5rem',
                    display: 'flex',
                    gap: '0.5rem',
                    alignItems: 'center',
                    // padding: isDesktop ? '0.7rem 5.5rem' : '0.6rem 3rem',
                  }}
                  type={button?.component_call_action?.action === 'open_child_page' ? 'submit' : ''}
                  onClick={(e) => {
                    onClickActionButton(e, button)
                  }}>
                  {button?.title} {button?.icon && <i className={button?.icon}></i>}
                </Button>
              )
            } else {
              return (
                <Button
                  color={button?.color}
                  outline={button?.outline}
                  className='mr-2 btn rounded-pill subtitle1'
                  style={{
                    paddingInline: isDesktop ? '5.5rem' : '4rem',
                    display: 'flex',
                    gap: '0.5rem',
                    alignItems: 'center',
                    backgroundColor: primary_color,
                    // padding: isDesktop ? '0.7rem 4.5rem' : '0.6rem 3rem',
                    borderColor: primary_color,
                    borderRadius: '20px',
                  }}
                  type={button?.component_call_action?.action === 'open_child_page' ? 'submit' : ''}
                  onClick={(e) => {
                    onClickActionButton(e, button)
                  }}>
                  {button?.title} {button?.icon && <i className={button?.icon}></i>}
                </Button>
              )
            }
            return (
              <Button
                color={button?.color}
                outline={button?.outline}
                className='mr-2 rounded-pill subtitle1'
                style={{
                  paddingInline: isDesktop ? '4rem' : '2rem',
                  display: 'flex',
                  gap: '0.5rem',
                  alignItems: 'center',
                  backgroundColor: primary_color,
                  padding: isDesktop ? '0.5rem 2rem' : '0.3rem 1rem',
                  borderColor: primary_color,
                  borderRadius: '20px',
                }}
                type={button?.component_call_action?.action === 'open_child_page' ? 'submit' : ''}
                onClick={(e) => {
                  onClickActionButton(e, button)
                }}>
                {button?.title} {button?.icon && <i className={button?.icon}></i>}
              </Button>
            )
          })}
        </div>
      </form>
    </div>
  )
}

const PublicForm = ({
  form,
  parentData,
  data,
  pageData,
  setActivePage,
  setParentData,
  setSearchData,
  setFilterData,
  onGoBackToHomePage,
  unitData,
  setUnitData,
  formsData,
  setFormData,
  widget_uuid,
}) => {
  return (
    <>
      {form?.repeatable_form ? (
        <RenderRepeatableForm
          form={form}
          parentData={parentData}
          data={data}
          pageData={pageData}
          setActivePage={setActivePage}
          setParentData={setParentData}
          setSearchData={setSearchData}
          setFilterData={setFilterData}
          onGoBackToHomePage={onGoBackToHomePage}
          unitData={unitData}
          setUnitData={setUnitData}
          formsData={formsData}
          setFormData={setFormData}
          widget_uuid={widget_uuid}
        />
      ) : (
        <RenderSingleForm />
      )}
    </>
  )
}

export default PublicForm
