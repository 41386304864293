import React, { useState, useEffect } from 'react'
import { Table, Input, Pagination, PaginationItem, PaginationLink, Button } from 'reactstrap'

import Loader from 'react-loaders'
import { primary_color } from '../../../../Helper/uiHelper'
import useFetchLockerCodes from '../../../../Queries/BlockPlan/useFetchLockerCodes'
import IconSet from '../../../../CMSComponent/icon'
import AddEditForm from './addEditForm'

const checkUserSelected = (users, uuid) => {
  if (users?.includes(uuid)) {
    return true
  } else {
    return false
  }
}

const CodesTable = ({ blockDetails, form }) => {
  const [blockName, setBlockName] = useState('')
  const [lockerNumber, setLockerNumber] = useState('')
  const [code, setCode] = useState()
  const [note, setNote] = useState('')
  const [page, setPage] = useState()
  const [academicYear, setAcademicYear] = useState()

  const [selectedLocker, setSelectedLocker] = useState([])
  const [editData, setEditData] = useState(false)

  const handleLockerSelection = (checked, locker) => {
    if (checked) {
      const newUser = [...selectedLocker]
      newUser?.push(locker)
      setSelectedLocker(newUser)
    } else {
      const newUser = [...selectedLocker]
      let index
      newUser?.map((item, i) => {
        if (item?.id === locker?.id) {
          index = i
        }
      })
      newUser?.splice(index, 1)
      setSelectedLocker(newUser)
    }
  }

  const { data: lockerData, isLoading } = useFetchLockerCodes({
    location_id: blockDetails?.id,
    page,
    item_date_time_id_label: academicYear,
    location_id_label: blockName,
    name: lockerNumber,
    code,
    note,
  })

  const selectedLockerUUID = selectedLocker?.map((item) => item?.id)

  if (editData)
    return (
      <AddEditForm
        form={form}
        defaultValue={editData}
        onCloseForm={() => {
          setEditData()
        }}></AddEditForm>
    )
  return (
    <div className='px-5 mt-4 merge-user-table'>
      {/* <div className='float-right'>
        <Button
          className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
          // onClick={onPrintClick}
          disabled={true}>
          <IconSet icon='fa-regular fa-print' color='light' />
        </Button>
        <Button
          className={`btn-success border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
          onClick={() => {
            setIsFormOpen(true)
          }}>
          <IconSet icon='fa-solid fa-plus' color='light' />
        </Button>
        <Button
          className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
          // onClick={onPrintClick}
          disabled={true}>
          <IconSet icon='fa-solid fa-sync' color='light' />
        </Button>
        <Button
          className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
          onClick={() => {
            setAcademicYear('')
            setNote('')
            setCode('')
            setLockerNumber('')
            setBlockName('')
          }}>
          <IconSet icon='fas fa-ban' color='light' />
        </Button>
      </div> */}
      <Table bordered hover>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
              Academic Year
            </th>
            <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
              Block Name
            </th>
            <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
              Locker Number
            </th>
            <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
              Codes
            </th>
            <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
              Notes
            </th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th>
              <Input type='text' placeholder='Academic year' value={academicYear} onChange={(e) => setAcademicYear(e.target.value)} />
            </th>
            <th>
              <Input type='text' placeholder='Block' value={blockName} onChange={(e) => setBlockName(e.target.value)} />
            </th>
            <th>
              <Input type='text' placeholder='Locker Number' value={lockerNumber} onChange={(e) => setLockerNumber(e.target.value)} />
            </th>
            <th>
              <Input type='text' placeholder='Code' value={code} onChange={(e) => setCode(e.target.value)} />
            </th>
            <th>
              <Input type='text' placeholder='Note' value={note} onChange={(e) => setNote(e.target.value)} />
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={5}>
                <div className='d-flex justify-content-center' style={{ marginTop: '4rem', marginBottom: '8rem' }}>
                  <Loader type='ball-pulse' color='blue' />
                </div>
              </td>
            </tr>
          ) : (
            <>
              {lockerData?.data?.data.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <input
                        type='checkbox'
                        checked={checkUserSelected(selectedLockerUUID, item?.id)}
                        onChange={(e) => handleLockerSelection(e.target.checked, item)}></input>
                    </td>
                    <td>
                      <Button
                        className={`btn-icon btn-icon-only btn btn-primary btn-sm`}
                        style={{ background: primary_color }}
                        onClick={() => {
                          setEditData(item)
                        }}>
                        <IconSet icon='far fa-edit' color='light' />
                      </Button>
                    </td>
                    <td className='subtitle1'>{item.item_date_time_id_label}</td>
                    <td className='subtitle1'>{item.location_id_label}</td>
                    <td className='subtitle1'>{item.name}</td>
                    <td className='subtitle1'>{item.code}</td>
                    <td className='subtitle1' dangerouslySetInnerHTML={{ __html: item.note }}>
                    </td>
                  </tr>
                )
              })}
            </>
          )}
        </tbody>
      </Table>

      <div className='d-flex justify-content-center'>
        <Pagination aria-label='Page navigation example' style={{ flexWrap: 'wrap' }}>
          {lockerData?.data?.links?.map((item, key) => {
            return (
              <PaginationItem active={item.active} disabled={item.url === null} key={key}>
                <PaginationLink
                  first
                  onClick={() => {
                    console.log('pagination:::::::', item)
                    if (item?.label?.toLowerCase()?.includes('previous')) {
                      setPage(parseInt(lockerData?.data?.current_page) - 1)
                    } else if (item?.label?.toLowerCase()?.includes('next')) {
                      setPage(parseInt(lockerData?.data?.current_page) + 1)
                    } else {
                      setPage(parseInt(item?.label))
                    }
                  }}>
                  {item.label.replace('&laquo;', '').replace('&raquo;', '')}
                </PaginationLink>
              </PaginationItem>
            )
          })}
        </Pagination>
      </div>
    </div>
  )
}

export default CodesTable
