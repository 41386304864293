import { RiDoorLine } from 'react-icons/ri'
import { primary_color } from '../../../Helper/uiHelper'

const LockerListing = ({
  lockers,
  selectedLockerIds,
  setSelectedLockerIds,
  isLockerSelectable,
  setSelectedLockers,
  selectedLockers,
  isSelectSingleLocker,
  isDraggable,
  archievedLocker,
  setArchievedLocker,
  setLockers,
  onDeleteEmptyLocker,
}) => {
  const lockerColWidth = 131.3
  const lockerColCount = lockers?.[0]?.length

  const handleDragStart = (event, id) => {
    event.dataTransfer.setData('blockId', id)
    // const element = document.getElementById('archieve_area')
    // element.scrollIntoView()
  }

  const getColor = (item) => {
    if (item?.archieved) {
      return '#8E8E8E'
    } else if (item?.is_maintanance) {
      return '#F87171'
    } else {
      return primary_color
    }
  }
  const allowDrop = (event) => {
    event.preventDefault()
  }
  const handleDrop = (event, i, index) => {
    event.preventDefault()
    const blockId = parseInt(event.dataTransfer.getData('archievedBlockId'), 10)
    if (!blockId) return
    let item
    let newArchievedLockers = []
    archievedLocker?.map((locker) => {
      if (locker?.id === blockId) {
        item = locker
      } else {
        newArchievedLockers?.push(locker)
      }
    })
    setArchievedLocker(newArchievedLockers)
    const newLockers = lockers?.map((row, rowIndex) => {
      if (rowIndex === i) {
        return row?.map((col, colInd) => {
          if (colInd === index) {
            return {
              ...col,
              ...item,
              archieved: false,
            }
          } else {
            return col
          }
        })
      } else {
        return row
      }
    })
    setLockers(newLockers)
  }

  
  return (
    <div className=' mb-2 !w-100 custom-scrollbar' style={{ overflow: 'auto' }}>
      <hr className='my-5'></hr>
      <div className='d-flex align-items-center mb-5 mt-5' style={{ fontWeight: '500' }}>
        {/* <div className='rounded-pill mr-2 subtitle1' style={{ height: '1rem', width: '1rem', background: '#059669CC' }}></div> Currently Booked */}
        <div className='rounded-pill mr-2 ml-3 subtitle1' style={{ height: '1rem', width: '1rem', background: primary_color }}></div> Active
        {/* <div className='rounded-pill mr-2 ml-3 subtitle1' style={{ height: '1rem', width: '1rem', background: '#06880BCC' }}></div> Booked */}
        <div className='rounded-pill mr-2 ml-3 subtitle1' style={{ height: '1rem', width: '1rem', background: '#F87171' }}></div> Maintanance
      </div>
      <hr className='my-5'></hr>
      <div style={{ width: lockerColWidth * lockerColCount + 'px' }} className='d-flex flex-wrap'>
        {lockers?.map((locakerItem, i) => {
          return locakerItem?.map((item, index) => {
            return (
              <div
                key={'locker' + index + i}
                onClick={() => {
                  if (!isLockerSelectable) return
                  if (isSelectSingleLocker) {
                    setSelectedLockerIds([item?.id])
                    setSelectedLockers([item])
                    return
                  }
                  if (selectedLockerIds?.includes(item?.id)) {
                    const newIds = [...selectedLockerIds]
                    let itemIndex = newIds?.indexOf(item?.id)
                    newIds?.splice(itemIndex, 1)
                    setSelectedLockerIds(newIds)

                    const newLockers = [...selectedLockers]
                    newLockers?.splice(itemIndex, 1)
                    setSelectedLockers(newLockers)
                  } else {
                    const newIds = [...selectedLockerIds]
                    newIds?.push(item?.id)
                    setSelectedLockerIds(newIds)

                    const newLockers = [...selectedLockers]
                    newLockers?.push(item)
                    setSelectedLockers(newLockers)
                  }

                  // onChangeValue(item?.reserved, item?.id, item)
                }}
                className='position-relative'
                data-id={item?.id}
                draggable={isDraggable}
                onDragStart={(event) => handleDragStart(event, item.id)}
                onDrop={(e) => handleDrop(e, i, index)}
                onDragOver={allowDrop}
                style={{
                  width: '8rem',
                  margin: '0.1rem',
                  height: '10rem',
                  background: !item?.display ? 'transparent' : getColor(item),
                  cursor: isLockerSelectable ? 'pointer' : 'default',
                  border: selectedLockerIds?.includes(item?.id) && `2px solid #001AFF`,
                }}>
                {!item?.display && (
                  <div
                    className='cursor-pointer position-absolute subtitle1'
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      onDeleteEmptyLocker(item?.id)
                    }}
                    style={{
                      right: '-1rem',
                      top: '-1rem',
                      background: 'rgb(248, 113, 113)',
                      padding: '0rem 0.5rem',
                      borderRadius: '14px',
                      fontWeight: '500',
                      zIndex: '10000',
                    }}>
                    <i class='fa-solid fa-xmark text-white'></i>
                  </div>
                )}

                {/* {!isDisplayZero ? (
                  <div
                    className='m-1 position-absolute subtitle1'
                    style={{
                      left: '0.5rem',
                      top: '0.25rem',
                      background: 'white',
                      padding: '0rem 0.5rem',
                      borderRadius: '14px',
                      fontWeight: '500',
                    }}>
                    <div>£{item?.price}</div>
                  </div>
                ) : null} */}

                <div className='position-absolute' style={{ top: '50%', left: '50%', transform: 'translate(-50% , -50%)' }}>
                  {/* <i class='fa-thin fa-door-closed' style={{ fontSize: '5rem' }}></i> */}
                  {!item?.archieved && <RiDoorLine style={{ color: '#DADADA', fontSize: '6rem' }} />}
                </div>
                <div
                  className='text-align-center mt-2 subtitle1'
                  style={{
                    color: '#EDF0F7',
                    position: 'absolute',
                    bottom: '1rem',
                    fontWeight: '500',
                    left: '50%',
                    transform: 'translate(-50%)',
                  }}>
                  {item?.name}
                </div>
              </div>
            )
          })
        })}
      </div>
    </div>
  )
}

export default LockerListing
