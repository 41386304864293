import React, { useEffect, useState } from 'react'
import { Button, Nav, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { resetPublicUser } from '../../Redux/Reducers/user'
import { setCookie } from '../../Helper/cookieHelper'
import { container_width, primary_color, success_color } from '../../Helper/uiHelper'

import useMediaQuery from '../../CustomHook/useMediaQuery'
import { BiArrowToLeft, BiArrowToRight } from 'react-icons/bi'
import DesktopNavigation from './DesktopNavigation'
import { LoginForm } from '../Widget/PubliicAuth/PublicLogin'
import Register from '../Widget/PubliicAuth/Register'
import { FaUserCircle } from 'react-icons/fa'
import { setToggleHeaderSideBar } from '../../Redux/Reducers/sideBar'
import { editMode as updateCMSEditMode } from '../../Redux/Reducers/CMS'
import { clearNav } from '../../Redux/Reducers/navigations'

const dummy_data = [
  {
    name: 'Home',
    icon: 'fa-solid fa-house',
    route: 'home-page-list',
  },
  {
    name: 'About',
    icon: 'fa-solid fa-address-card',
    route: 'about-us',
  },
  {
    name: 'Locker',
    icon: 'fa-solid fa-door-closed',
    route: 'book-my-locker',
  },

  // {
  //   name: 'Terms',
  //   icon: 'fas fa-file-contract',
  //   route: 'terms-and-condition',
  // },
  // {
  //   name: 'Privacy policy',
  //   icon: 'fas fa-shield-alt',
  //   route: 'privacy-policies',
  // },
  {
    name: 'Dashboard',
    icon: 'fa-solid fa-compass',
    action: 'redirect-to-cms',
    isLoginRequired: true,
  },
  {
    name: 'Logout',
    icon: 'fa-solid fa-sign-out',
    action: 'logout',
    isLoginRequired: true,
    isOnlyMobile: true,
  },
  // {
  //   name: 'More',
  //   icon: 'fa-sharp fa-solid fa-arrow-down-to-line',
  // },
]

const checkIsAdminRole = (r) => {
  let role = r?.name || r

  console.log('role::::::::', role)
  if (!role) return false

  if (role?.toLowerCase() === 'admin' || role?.toLowerCase() === 'superadmin' || role?.toLowerCase() === 'super_admin') {
    return true
  } else {
    return false
  }
}

const PublicHeader = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const isDesktop = useMediaQuery('(min-width:768px)')

  const [collapsed, setCollapsed] = useState(false)
  const headerBackgroundColor = useSelector((state) => state.ThemeOptions.headerBackgroundColor)
  const publicUser = useSelector((state) => state.User.publicUser)
  const publicPage = useSelector((state) => state.CMS.publicPage)
  const editMode = useSelector((state) => state.CMS.editMode)

  const [showLogin, setShowLogin] = useState(false)
  const [showRegister, setShowRegister] = useState(false)
  const handleItemClick = (item) => {
    history.push('/' + item?.route)
  }
  const isSideBarOpen = useSelector((state) => state?.SideBar?.isHeaderSideBarOpen)

  useEffect(() => {
    dispatch(setToggleHeaderSideBar(false))
  }, [])

  const toggleHamburger = () => {
    dispatch(setToggleHeaderSideBar(!isSideBarOpen))
    setCollapsed(!collapsed)
  }

  return (
    <>
      <div
        className={` header  d-flex justify-content-center align-items-center ${isDesktop ? 'px-3vw' : ''} ${headerBackgroundColor}`}
        style={{
          height: '60px',
          position: 'sticky',
          top: 0,
          background: 'white',
          zIndex: 1000,
          boxShadow: '0px 0px 25px 0px rgba(4,159,219,0.18)',
        }}>
        {/* <div className='d-flex justify-content-between align-items-center' style={{ width: container_width }}> */}
        <div className='d-flex justify-content-between align-items-center' style={{ width: '100%' }}>
          <div className='logo ml-2'>
            <img
              alt='logo'
              style={{ cursor: 'pointer' }}
              onClick={() => history.push('/')}
              src={`${process.env.REACT_APP_URL}/logo/locker-logo.png`}
              height={isDesktop ? 50 : 27}
            />
          </div>
          {checkIsAdminRole(publicUser?.role) && (
            <Button
              className='mx-5 subtitle1 rounded-pill'
              style={{
                backgroundColor: editMode ? success_color : primary_color,
                paddingInline: isDesktop ? '2rem' : '1rem',
                borderColor: primary_color,
                borderRadius: '30px',
                width: '15rem',
              }}
              onClick={() => {
                dispatch(updateCMSEditMode(!editMode))
                // history.push('/welcome/login')
              }}>
              Edit CMS
            </Button>
          )}

          {isDesktop ? (
            <div className='position-relative w-100'>
              <DesktopNavigation data={dummy_data} />
            </div>
          ) : null}

          <Nav className='header-items align-items-center'>
            {/* {publicPage.map((item) => {
              if (item?.route?.includes('payment')) return
              return (
                <div
                  key={item?.uuid}
                  className={`mx-2 align-self-center cursor-pointer ${
                    window.location.pathname === `/${item?.route}` ? 'border-bottom font-weight-bold' : ''
                  }`}
                  style={{ color: primary_color }}
                  onClick={() => handleItemClick(item)}>
                  {cms(item?.cms_key)}
                </div>
              )
            })} */}
            <div className='pr-2 d-flex align-items-center' style={{ width: 'max-content' }}>
              {!isDesktop ? (
                <div>
                  <button className={`btn h2 p-0`} style={{ color: primary_color, fontSize: '1.5rem' }} onClick={toggleHamburger}>
                    {collapsed ? <BiArrowToRight /> : <BiArrowToLeft />}
                  </button>
                </div>
              ) : null}

              {!publicUser?.email && (
                <>
                  <Button
                    outline
                    className='ml-3 subtitle1 rounded-pill'
                    variant='outline-primary' // Change the variant to the desired color
                    onClick={() => {
                      setShowRegister(true)
                    }}
                    style={{
                      borderColor: primary_color,

                      paddingInline: isDesktop ? '2rem' : '1rem',
                      color: primary_color,
                      borderRadius: '30px',
                    }}>
                    Register
                  </Button>
                  <Button
                    className='mx-2 subtitle1 rounded-pill'
                    style={{
                      backgroundColor: primary_color,
                      paddingInline: isDesktop ? '2rem' : '1rem',
                      borderColor: primary_color,
                      borderRadius: '30px',
                    }}
                    onClick={() => {
                      history.push('/welcome/login')
                    }}>
                    Login
                  </Button>
                </>
              )}
              {publicUser?.email && (
                <div className='d-flex'>
                  <div
                    className='d-flex align-items-center ml-3 cursor-pointer'
                    onClick={() => {
                      history.push('/profile-update')
                    }}>
                    {publicUser?.photo ? (
                      <img
                        src={process.env.REACT_APP_STORAGE + publicUser?.photo}
                        height={'50px'}
                        width={'50px'}
                        style={{ borderRadius: '100%' }}></img>
                    ) : (
                      <FaUserCircle style={{ color: primary_color, fontSize: '1.50rem' }} />
                    )}
                    <span className='ml-2 mr-2' style={{ maxWidth: 70 }}>
                      {publicUser?.first_name}
                    </span>
                  </div>
                  {isDesktop ? (
                    <Button
                      className='mx-2 rounded-pill px-4'
                      style={{ backgroundColor: primary_color, borderColor: primary_color, borderRadius: '20px' }}
                      onClick={() => {
                        dispatch(resetPublicUser())
                        setCookie('AUTH_TOKEN')
                        dispatch(clearNav())
                      }}>
                      Logout
                    </Button>
                  ) : null}
                </div>
              )}
            </div>
          </Nav>
        </div>
      </div>
      {!isDesktop ? (
        <div>
          <DesktopNavigation data={dummy_data} isSidebarOpen={collapsed} isSidebar={true} />
        </div>
      ) : null}

      <Modal
        isOpen={showLogin}
        toggle={() => {
          setShowLogin(false)
        }}
        style={{ width: isDesktop ? '30rem' : '95%' }}>
        <ModalHeader
          toggle={() => {
            setShowLogin(false)
          }}>
          <div className='h4 font-weight-bold' style={{ color: primary_color }}>
            Login now!
          </div>
        </ModalHeader>
        <ModalBody>
          <LoginForm onLogin={() => setShowLogin(false)} />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showRegister}
        toggle={() => {
          setShowRegister(false)
        }}
        style={{ width: isDesktop ? '30rem' : '95%' }}>
        <ModalHeader
          toggle={() => {
            setShowRegister(false)
          }}>
          <div className='h4 font-weight-bold' style={{ color: primary_color }}>
            Register now!
          </div>
        </ModalHeader>
        <ModalBody>
          <Register onRegister={() => setShowRegister(false)} />
        </ModalBody>
      </Modal>
    </>
  )
}

export default PublicHeader
