import { useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { primary_color } from '../../../Helper/uiHelper'
import { useDispatch, useSelector } from 'react-redux'
import { request } from '../../../Redux/Sagas/requests/api'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { fetchUpdateApiData } from '../../../Redux/Reducers/CMS'

const ApproveRefundButton = ({ gridRelatedData }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.User.user.full_name)
  const role = useSelector((state) => state.User.user.role.name)

  const [openModal, setOpenModal] = useState(false)
  const [loader, setLoader] = useState(false)

  const refundRequestRow = gridRelatedData?.gridSelectedRow?.filter((row) => {
    if (row?.original?.status?.toLowerCase()?.includes('waiting')) {
      return true
    } else {
      return false
    }
  })
  const refundIds = refundRequestRow?.map((item) => item?.original?.id)

  const onApproveRefund = async () => {
    setLoader(true)
    const promises = refundRequestRow?.map((row) => {
      const item = row?.original
      let payload = {
        id: parseInt(item?.id),
        refund_note: user + ' approved the refund request',
        refund_amount: item?.amount,
      }
      return request('update-refund-status', 'POST', payload)
    })

    Promise.all(promises)
      .then(() => {
        dispatch(setNotification({ type: 'success', message: 'Request approved' }))
        dispatch(fetchUpdateApiData())
        setLoader(false)
        setOpenModal(false)
      })
      .catch((err) => {
        dispatch(setNotification({ type: 'success', message: 'Something went wrong!' }))
        dispatch(fetchUpdateApiData())
        setLoader(false)
        setOpenModal(false)
      })
  }
  if (!role?.toLowerCase()?.includes('admin')) return
  return (
    <>
      <Button
        className='bg-primary px-3 rounded text-white mr-2'
        disabled={gridRelatedData?.gridSelectedRow?.length > 0 ? false : true}
        style={{ border: 'none', paddingTop: '0.3rem', paddingBottom: '0.3rem' }}
        onClick={() => {
          setOpenModal(true)
        }}>
        Approve
      </Button>
      <Modal
        isOpen={openModal}
        centered={true}
        toggle={() => {
          setOpenModal(false)
        }}
        style={{ width: '50rem', boxShadow: 'none', maxWidth: '45rem' }}>
        <ModalHeader style={{ backgroundColor: '#FFF', border: 'none', padding: '2rem', textAlign: 'center' }}>
          <div className='p font-weight-bold text-center' style={{ color: primary_color }}>
            {refundRequestRow?.length === 0 && 'Please select atleast one row of refund request'}
            {refundRequestRow?.length > 0 && 'Are you sure you want to approve the refund request for Ids: ' + refundIds?.join(', ') + ' ?'}
          </div>
        </ModalHeader>
        <ModalBody>
          <div className='d-flex justify-content-between'>
            <button
              className='btn text-white rounded-pill mt-2'
              type='button'
              onClick={() => {
                onApproveRefund()
              }}
              disabled={refundRequestRow?.length === 0 ? true : false}
              style={{ background: primary_color, fontWeight: 700, width: '48%' }}>
              {loader ? 'Approving...' : 'Yes'}
            </button>
            <button
              className='btn rounded-pill mt-2 text-danger border-danger'
              type='button'
              onClick={() => {
                setOpenModal(false)
              }}
              style={{ fontWeight: 700, width: '48%' }}>
              Cancel
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ApproveRefundButton
