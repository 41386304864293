import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AvForm } from 'availity-reactstrap-validation'

import RenderFields from '../../../CMSComponent/Forms/renderFields'
import { primary_color } from '../../../Helper/uiHelper'
import { request } from '../../../Redux/Sagas/requests/api'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { displayVertically } from './manageLocker'
import LockerListing from './lockerListing'

const BlockMaintanance = ({ setBlocketails, blockDetails, form, onCloseForm }) => {
  const dispatch = useDispatch()

  const [formFieldValue, setFormFieldValue] = useState()
  const [loading, setLoading] = useState(false)
  const [lockers, setLockers] = useState()
  const [selectedLockerIds, setSelectedLockerIds] = useState([])
  const [selectedLockers, setSelectedLockers] = useState([])

  useEffect(() => {
    setLockers(displayVertically(blockDetails?.lockers, blockDetails?.lockerHeight))
  }, [blockDetails])

  const onAddMaintanance = (values) => {
    if (selectedLockerIds?.length === 0) {
      dispatch(setNotification({ type: 'error', message: 'Please select atleast one locker!' }))
      return
    }
    let payload = {
      ...values,
      item_id: selectedLockerIds?.[0],
    }
    setLoading(true)
    request('maintenance-locker', 'POST', payload).then((res) => {
      if (res.status === 200 || res.status === 202 || res.status === 201) {
        dispatch(setNotification({ type: 'success', message: 'Locker empty successfully!' }))
        request('get-location-data', 'POST', { location_id: blockDetails?.id }).then((res) => {
          if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
            setBlocketails(res?.data)
          }
          setLoading(false)
        })
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message || 'Something went wrong!' }))
        setLoading(false)
      }
    })
    setSelectedLockerIds([])
    setSelectedLockers([])
  }

  console.log('form values::::::::::', form)
  return (
    <div className='mt-5'>
      <div className='text-muted body1 mb-4'>Maintanance</div>
      <div>
        <AvForm
          onValidSubmit={(e, values) => {
            setLoading(true)
            onAddMaintanance(values)
            // onSubmitBlockDetails(values)
            console.log('form values::::::::::', values)
          }}>
          <RenderFields
            allForm={[form]}
            content={[form]}
            twoCol={true}
            // defaultFormValue={defaultSingleFormValue}
            formFieldValue={formFieldValue}
            setFormFieldValue={setFormFieldValue}
            noDefaultValue={true}
          />
          <div>
            <button
              className='btn rounded-pill mt-2 text-danger border-danger mr-3'
              type='button'
              onClick={(e) => {
                onCloseForm()
                e.preventDefault()
                e.stopPropagation()
              }}
              style={{ fontWeight: 700, width: '12rem' }}>
              Cancel
            </button>
            <button
              className='btn text-white rounded-pill mt-2'
              disabled={!!loading || selectedLockerIds?.length === 0}
              type='submit'
              style={{ background: primary_color, fontWeight: 700, width: '14rem' }}>
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </AvForm>
      </div>
      <div>
        {lockers?.length > 0 && (
          <div className='mt-5'>
            <LockerListing
              lockers={lockers}
              isLockerSelectable={true}
              setSelectedLockerIds={setSelectedLockerIds}
              selectedLockerIds={selectedLockerIds}
              setSelectedLockers={setSelectedLockers}
              selectedLockers={[]}
              isSelectSingleLocker={true}></LockerListing>
          </div>
        )}
      </div>
    </div>
  )
}

export default BlockMaintanance
