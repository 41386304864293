import { Input, InputGroup, InputGroupAddon, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import Select from '../../../CMSComponent/Forms/Fields/select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faPoundSign } from '@fortawesome/free-solid-svg-icons'

import { primary_color } from '../../../Helper/uiHelper'

const AdminAmountModal = ({ extraChargeForm, data, setExtraChargeForm, setIsNewTransactionOpen, setExtraPaymentAmount, extraPaymentAmount }) => {
  return (
    <>
      {' '}
      <ModalHeader style={{ backgroundColor: '#FFF', border: 'none', padding: '2rem' }}>
        <div style={{ color: primary_color }}>New Transaction</div>
      </ModalHeader>
      <ModalBody>
        <div>
          <AvForm>
            {data?.form?.[0]?.component_call_fields?.[0] && (
              <Select
                data={data?.form?.[0]?.component_call_fields?.[0]}
                id={'name'}
                onChangeAction={(aa, actions, field, value, label) => {
                  setExtraChargeForm({
                    ...extraChargeForm,
                    name: { value, label },
                  })
                }}></Select>
            )}
            <Label>Amount</Label>
            <InputGroup>
              <InputGroupAddon addonType='prepand' style={{ cursor: 'pointer' }}>
                <div className='input-dollar-icon input-group-text'>
                  <FontAwesomeIcon icon={faPoundSign} />
                </div>
              </InputGroupAddon>
              <Input
                type={'number'}
                onChange={(e) => {
                  setExtraChargeForm({
                    ...extraChargeForm,
                    amount: e.target.value,
                  })
                }}
                id={'amount'}
                value={extraChargeForm?.amount}
              />
            </InputGroup>
          </AvForm>
          <div className='d-flex'>
            <button
              className='btn rounded-pill mt-2 text-danger border-danger mr-3'
              type='button'
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setExtraChargeForm({})
                setIsNewTransactionOpen(false)
              }}
              style={{ fontWeight: 700, width: '8rem' }}>
              Cancel
            </button>
            <button
              className='btn text-white rounded-pill mt-2'
              type='submit'
              onClick={() => {
                setExtraPaymentAmount([...extraPaymentAmount, extraChargeForm])
                setExtraChargeForm({})
                setIsNewTransactionOpen(false)
              }}
              style={{ background: primary_color, fontWeight: 700, width: '8rem' }}>
              Save
            </button>
          </div>
        </div>
      </ModalBody>
    </>
  )
}

export default AdminAmountModal
