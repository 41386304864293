import React, { useState, useEffect } from 'react'
import { Table, Input, Pagination, PaginationItem, PaginationLink, Button } from 'reactstrap'
import DatePicker from 'react-datepicker'
import moment from "moment"

import Loader from 'react-loaders'
import { primary_color } from '../../../../Helper/uiHelper'
import useFetchVoucher from '../../../../Queries/BlockPlan/useFetchVoucher'
import IconSet from '../../../../CMSComponent/icon'
import { useSelector } from 'react-redux'
import AssignForm from './assignForm'
import AddVoucher from './addVoucher'

const checkUserSelected = (users, uuid) => {
  if (users?.includes(uuid)) {
    return true
  } else {
    return false
  }
}

const VoucherTable = ({ tab }) => {
  const currentRowData = useSelector((state) => state.CMS.currentRow)
  const [isFormOpen, setIsFormOpen] = useState(false)

  const [validFrom, setValidFrom] = useState(null)
  const [validTo, setValidTo] = useState(null)
  const [code, setCode] = useState()
  const [discount, setDiscount] = useState('')
  const [page, setPage] = useState()
  const [email, setEmail] = useState()

  const [selectedVoucher, setSelectedVoucher] = useState([])
  const [assignData, setAssignData] = useState(false)

  const handleVoucherSelection = (checked, voucher) => {
    if (checked) {
      const newUser = [...selectedVoucher]
      newUser?.push(voucher)
      setSelectedVoucher(newUser)
    } else {
      const newUser = [...selectedVoucher]
      let index
      newUser?.map((item, i) => {
        if (item?.id === voucher?.id) {
          index = i
        }
      })
      newUser?.splice(index, 1)
      setSelectedVoucher(newUser)
    }
  }

  const {
    data: voucherData,
    isLoading,
    refetch,
  } = useFetchVoucher({
    domain_id: currentRowData?.[0]?.id,
    page,
    email,
    valid_to: validTo,
    valid_from: validFrom,
    code,
    discount,
  })

  const selectedVoucherId = selectedVoucher?.map((item) => item?.id)

  if (assignData)
    return (
      <AssignForm
        defaultValue={assignData}
        refetch={refetch}
        onCloseForm={() => {
          setAssignData()
        }}></AssignForm>
    )

  if (isFormOpen) {
    return <AddVoucher grid_id={tab?.component_call_form?.[0]?.main_grid?.id} onCloseForm={() => setIsFormOpen(false)} refetch={refetch}></AddVoucher>
  }
  return (
    <div className='px-5 mt-4 merge-user-table'>
      <div className='float-right'>
        <Button
          className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
          // onClick={onPrintClick}
          disabled={true}>
          <IconSet icon='fa-regular fa-print' color='light' />
        </Button>
        <Button
          className={`btn-success border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
          onClick={() => {
            setIsFormOpen(true)
          }}>
          <IconSet icon='fa-solid fa-plus' color='light' />
        </Button>
        <Button
          className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
          onClick={() => {
            refetch()
          }}>
          <IconSet icon='fa-solid fa-sync' color='light' />
        </Button>
        <Button
          className={`btn-warning border-0 btn-icon btn-icon-only fsize-1 m-2 no-print`}
          onClick={() => {
            setEmail('')
            setDiscount('')
            setCode('')
            setValidTo(null)
            setValidFrom(null)
          }}>
          <IconSet icon='fas fa-ban' color='light' />
        </Button>
      </div>
      <Table bordered hover className='notes-type-table'>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
              Start Date
            </th>
            <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
              End Date
            </th>
            <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
              Code
            </th>
            <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
              Discount(%)
            </th>
            <th className='subtitle1 font-weight-bold py-3' style={{ color: primary_color }}>
              Email
            </th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th>
              <DatePicker
                selected={validFrom && moment(validFrom, 'YYYY-MM-DD').toDate()}
                className='date-picker remove-outline'
                dateFormat='d MMMM , yyyy'
                placeholder='Search'
                onChange={(e) => {
                  setValidFrom(moment(e).format('YYYY-MM-DD'), true)
                }}
              />
            </th>
            <th>
              <DatePicker
                selected={validTo && moment(validTo, 'YYYY-MM-DD').toDate()}
                className='date-picker remove-outline'
                dateFormat='d MMMM , yyyy'
                placeholder='Search'
                onChange={(e) => {
                  setValidTo(moment(e).format('YYYY-MM-DD'), true)
                }}
              />
            </th>
            <th>
              <Input type='text' placeholder='Search' value={code} onChange={(e) => setCode(e.target.value)} />
            </th>
            <th>
              <Input type='text' placeholder='Search' value={discount} onChange={(e) => setDiscount(e.target.value)} />
            </th>
            <th>
              <Input type='text' placeholder='Search' value={email} onChange={(e) => setEmail(e.target.value)} />
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={7}>
                <div className='d-flex justify-content-center' style={{ marginTop: '4rem', marginBottom: '8rem' }}>
                  <Loader type='ball-pulse' color='blue' />
                </div>
              </td>
            </tr>
          ) : (
            <>
              {voucherData?.data?.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <input
                        type='checkbox'
                        checked={checkUserSelected(selectedVoucherId, item?.id)}
                        onChange={(e) => handleVoucherSelection(e.target.checked, item)}></input>
                    </td>
                    <td>
                      <Button
                        className={`btn-icon btn-icon-only btn btn-primary btn-sm`}
                        style={{ background: primary_color }}
                        onClick={() => {
                          setAssignData(item)
                        }}>
                        Assign
                      </Button>
                    </td>
                    <td className='subtitle1'>{item.start && moment(item.start).format('DD-MM-YYYY')}</td>
                    <td className='subtitle1'>{item.end && moment(item.end).format('DD-MM-YYYY')}</td>
                    <td className='subtitle1'>{item.code}</td>
                    <td className='subtitle1'>{item.discount_percent}</td>
                    <td className='subtitle1'>{item.email}</td>
                  </tr>
                )
              })}
            </>
          )}
        </tbody>
      </Table>

      <div className='d-flex justify-content-center'>
        <Pagination aria-label='Page navigation example' style={{ flexWrap: 'wrap' }}>
          {voucherData?.links?.map((item, key) => {
            return (
              <PaginationItem active={item.active} disabled={item.url === null} key={key}>
                <PaginationLink
                  first
                  onClick={() => {
                    console.log('pagination:::::::', item)
                    if (item?.label?.toLowerCase()?.includes('previous')) {
                      setPage(parseInt(voucherData?.data?.current_page) - 1)
                    } else if (item?.label?.toLowerCase()?.includes('next')) {
                      setPage(parseInt(voucherData?.data?.current_page) + 1)
                    } else {
                      setPage(parseInt(item?.label))
                    }
                  }}>
                  {item.label.replace('&laquo;', '').replace('&raquo;', '')}
                </PaginationLink>
              </PaginationItem>
            )
          })}
        </Pagination>
      </div>
    </div>
  )
}

export default VoucherTable
