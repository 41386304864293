// Routes.js
import React, { Fragment, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useFetchNav } from '../../Queries/Nav/useFetchNav'

import Login from '../Auth/login'
import HandleLogout from '../Auth/logout'
import Register from '../Auth/register'
import ForgotPassword from '../Auth/forgotPassword'
import ResetPassword from '../Auth/resetPassword'
import AuthRoute from './authRoute'
import CMSPage from '../../CMSComponent/page'
import { ErrorBoundary } from '../ErrorPage/errorBoundry'
import Loader from '../../Components/loader'
import PublicPage from '../../CMSComponent/PageVariation/PublicPage/publicPage'
import PublicPageWrapper from '../../Components/publicPageWrapper'
import Profile from '../../Components/Profile/profile'
import UnSubscribe from '../Unsubscribe/unsubscribe'
import Wrapper from '../../Components/wrapper'
import ApproveRefund from '../../Components/PageSpecificComponents/Refund/approveRefund'
import { setLocalStorageItem } from '../../Helper/cookieHelper'

const Routes = () => {
  const navigation = useSelector((state) => state.Navigations)
  const cmsData = useSelector((state) => state.cmsData)
  const [frontRoutes, setFrontPages] = useState([])
  const { isLoading } = useFetchNav(navigation.lastUpdated === null)

  useEffect(()=>{
    setLocalStorageItem('currentURL', window.location.pathname + window.location.search)
  },[])

  useEffect(() => {
    if (navigation?.HeaderNav && navigation?.FooterNav) {
      setFrontPages([...navigation?.HeaderNav, ...navigation?.FooterNav])
    }
  }, [navigation])

  const defaultRoute = navigation?.HeaderNav?.find((item) => item.main_page)
  console.log('default route:::', defaultRoute, navigation)

  if (isLoading) {
    return <Loader />
  }

  return (
    <Fragment>
      <Switch>
        {/* Auth Routes */}
        <Route exact path='/welcome/login' component={Login} />
        <Route exact path='/welcome/register' component={Register} />
        <Route exact path='/forgot-password' component={ForgotPassword} />
        <Route exact path='/reset/:token' component={ResetPassword} />
        <Route exact path='/unsubscribe' component={UnSubscribe} />
        <Route
          exact
          path='/profile-update'
          component={() => (
            <PublicPageWrapper>
              <Profile />
            </PublicPageWrapper>
          )}
        />

        {/* User Route */}
        <AuthRoute path='/logout' component={HandleLogout} />
        <Route
          path='/link-expired'
          component={() => (
            <PublicPageWrapper>
              <div>Link expired...</div>
            </PublicPageWrapper>
          )}></Route>
        <ErrorBoundary>
          {frontRoutes.length > 0 && (
            <>
              {frontRoutes.map((page, key) => (
                <Route
                  key={key}
                  path={'/' + page.route}
                  component={() => (
                    <PublicPageWrapper>
                      <PublicPage page_uuid={page.page_uuid} />
                    </PublicPageWrapper>
                  )}
                />
              ))}
              {navigation?.InnerSidebarNav.map((item, key) => {
                return <AuthRoute exact key={key} path={'/' + item.route} page_uuid={item.page_uuid} component={CMSPage} />
              })}

              <AuthRoute
                exact
                path={'/refund-approve'}
                component={() => (
                  <Wrapper cmsData={cmsData}>
                    <ApproveRefund />
                  </Wrapper>
                )}
              />
            </>
          )}

          {defaultRoute && (
            <Route
              path='/'
              exact
              component={() => (
                <PublicPageWrapper>
                  <PublicPage page_uuid={defaultRoute.page_uuid} />
                </PublicPageWrapper>
              )}
            />
          )}
        </ErrorBoundary>
      </Switch>
      <ToastContainer />
    </Fragment>
  )
}

export default Routes
