import { useQuery } from 'react-query'
import axios from 'axios'
import { request } from '../../Redux/Sagas/requests/api'

const fetchData = async (postData) => {
  // const response = await axios.post('https://api.example.com/data', postData)
  const response = await request('get-voucher', 'POST', postData)
  return response.data
}

const useFetchVoucher = (postData) => {
  const query = useQuery(['fetchVoucher', postData], () => fetchData(postData), {
    enabled: !!postData, // Only run the query if postData is provided
  })

  return {
    data: query.data,
    error: query.error,
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
    isError: query.isError,
    refetch: query.refetch
  }
}

export default useFetchVoucher
