import { primary_color } from '../../../Helper/uiHelper'
import RenderFields from '../../../CMSComponent/Forms/renderFields'
import { AvForm } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import { isNumeric } from '../../../helper'
import { useDispatch, useSelector } from 'react-redux'
import { request } from '../../../Redux/Sagas/requests/api'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { fetchUpdateApi } from '../../../Redux/Sagas/rootSaga'
import { fetchUpdateApiData } from '../../../Redux/Reducers/CMS'

const AddRefund = ({ addDataDetail, addFormList, onCancel }) => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const [balance, setBalance] = useState()
  const dispatch = useDispatch()

  const [error, setError] = useState()
  const [isRaising, setIsRaising] = useState(false)

  useEffect(() => {
    request('get-payment-detail-by-id', 'POST', { id: currentRow?.[0]?.id }).then((res) => {
      if (res?.status === 200 || res?.status === 202) {
        setBalance(parseInt(res?.data?.data))
      } else {
        dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
        onCancel()
      }
    })

    return () => {
      onCancel()
    }
  }, [])

  const onRaiseRequest = (values) => {
    let payload = {
      ...values,
      payment_id: currentRow?.[0]?.id,
      base_url: window.location.origin,
    }
    setIsRaising(true)
    request('add-transaction-log', 'POST', payload).then((res) => {
      if (res?.status === 200 || res?.status === 202) {
        dispatch(setNotification({ type: 'success', message: 'Refund request raised' }))
        onCancel()
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message || 'Something went wrong' }))
      }
      dispatch(fetchUpdateApiData())
      setIsRaising(false)
    })
  }

  const handleValidation = (values) => {
    if (!values?.type) {
      setError('Type is required')
      setTimeout(() => {
        setError()
      }, 5000)
      return
    }
    if (values?.amount) {
      if (!isNumeric(parseInt(values?.amount))) {
        setError('Amount should be number')
        setTimeout(() => {
          setError()
        }, 5000)
        return
      }
      if (parseInt(balance) < parseInt(values?.amount)) {
        setError('Refund amount is more than paid amount')
        setTimeout(() => {
          setError()
        }, 5000)
        return
      }
    }
    onRaiseRequest(values)
  }

  return (
    <div className='mt-4 p-5'>
      <div className='h5 mb-5 font-weight-bold' style={{ color: primary_color }}>
        Send Refund Request
      </div>
      <div className='p-3 rounded d-flex  flex-column align-items-center' style={{ background: `${primary_color}33`, width: 'fit-content' }}>
        <div className='body2 font-weight-bold' style={{ color: primary_color }}>
          Available Amount
        </div>
        <div className='h4 font-weight-bold' style={{ color: primary_color }}>
          £{balance}
        </div>
      </div>

      <AvForm
        onValidSubmit={(e, values) => {
          handleValidation(values)
        }}
        style={{ marginBottom: '1rem', maxWidth: '50rem' }}
        className='mt-4'>
        <RenderFields allForm={addFormList} content={addDataDetail?.currentTabData} />
        {error && (
          <div class='alert alert-danger my-4' role='alert'>
            {error}
          </div>
        )}
        <div className='my-5 d-flex justify-content-end'>
          <button
            className='btn rounded-pill mt-2 text-danger border-danger mr-3'
            type='button'
            onClick={(e) => {
              onCancel()
              e.preventDefault()
              e.stopPropagation()
            }}
            style={{ fontWeight: 700, width: '8rem' }}>
            Cancel
          </button>
          <button
            className='btn text-white rounded-pill mt-2'
            disabled={!!error}
            type='submit'
            style={{ background: primary_color, fontWeight: 700, width: '8rem' }}>
            {isRaising ? 'Saving...' : 'Save'}
          </button>
        </div>
      </AvForm>
    </div>
  )
}

export default AddRefund
