import { useQuery } from 'react-query'
import axios from 'axios'
import { request } from '../../Redux/Sagas/requests/api'

const fetchData = async (postData) => {
  // const response = await axios.post('https://api.example.com/data', postData)
  let payload = {}
  Object.keys(postData)?.map((key)=>{
    if(key){
      payload[key] = postData[key]
    }
  })
  const response = await request('get-notes-data', 'POST', payload)
  return response.data
}

const useFetchSchoolNotes = (postData) => {
  const query = useQuery(['fetchSchoolNotes', postData], () => fetchData(postData), {
    enabled: !!postData, // Only run the query if postData is provided
  })

  return {
    data: query.data,
    error: query.error,
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
    isError: query.isError,
    refetch: query.refetch,
  }
}

export default useFetchSchoolNotes
