import { RiDoorLine } from 'react-icons/ri'
import { primary_color } from '../../../Helper/uiHelper'

const ArchieveArea = ({ lockers, setLockers, blockDetails, archievedLocker, setArchievedLocker }) => {
  const allowDrop = (event) => {
    event.preventDefault()
  }

  const handleDrop = (event) => {
    event.preventDefault()
    const blockId = parseInt(event.dataTransfer.getData('blockId'), 10)
    const newArchievedLockers = [...archievedLocker]
    const newLockers = lockers?.map((row) => {
      return row?.map((item) => {
        if (item?.id === blockId) {
          newArchievedLockers?.push(item)
          return {
            archieved: true,
            display: true,
          }
        } else {
          return item
        }
      })
    })
    setLockers(newLockers)
    setArchievedLocker(newArchievedLockers)
  }
  const handleDragStart = (event, id) => {
    event.dataTransfer.setData('archievedBlockId', id)
  }

  return (
    <div className='mt-5' id='archieve_area'>
      <div className='body1 mb-3' style={{ color: primary_color }}>
        Archive Lockers
      </div>
      <ul className='mb-5' style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li className='subtitle1 text-muted'>
          To add lockers to archive either “DRAG” locker into archive window or move another locker on to the locker you wish to add the archive.
        </li>
        <li className='subtitle1 text-muted'>
          To remove lockers from archive “DRAG” locker either to a blank space or on top of an existing locker. Note dragging the archive locker onto
          an existing locker will add other locker to archive.
        </li>
      </ul>
      <div className='d-flex align-items-center mb-2 mt-5' style={{ fontWeight: '500' }}>
        <div className='rounded-pill mr-2 ml-3 subtitle1' style={{ height: '1rem', width: '1rem', background: '#8E8E8E' }}></div> No Location
      </div>
      <hr className='my-5'></hr>
      <div style={{ minHeight: '20rem', background: '#8E8E8E33' }} className='d-flex flex-wrap' onDrop={handleDrop} onDragOver={allowDrop}>
        {archievedLocker?.map((item) => {
          return (
            <div
              key={'archievedlocker' + item?.id}
              className=' position-relative'
              data-id={item?.id}
              draggable={true}
              onDragStart={(event) => handleDragStart(event, item.id)}
              style={{
                width: '8rem',
                margin: '0.1rem',
                height: '10rem',
                background: '#8E8E8E',
                cursor: 'pointer',
              }}>
              {/* {!isDisplayZero ? (
                  <div
                    className='m-1 position-absolute subtitle1'
                    style={{
                      left: '0.5rem',
                      top: '0.25rem',
                      background: 'white',
                      padding: '0rem 0.5rem',
                      borderRadius: '14px',
                      fontWeight: '500',
                    }}>
                    <div>£{item?.price}</div>
                  </div>
                ) : null} */}

              <div className='position-absolute' style={{ top: '50%', left: '50%', transform: 'translate(-50% , -50%)' }}>
                {/* <i class='fa-thin fa-door-closed' style={{ fontSize: '5rem' }}></i> */}
                <RiDoorLine style={{ color: '#DADADA', fontSize: '6rem' }} />
              </div>
              <div
                className='text-align-center mt-2 subtitle1'
                style={{
                  color: '#EDF0F7',
                  position: 'absolute',
                  bottom: '1rem',
                  fontWeight: '500',
                  left: '50%',
                  transform: 'translate(-50%)',
                }}>
                {item?.name}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ArchieveArea
