import { AvField, AvForm } from 'availity-reactstrap-validation'
import { primary_color } from '../../../Helper/uiHelper'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { request } from '../../../Redux/Sagas/requests/api'

const MoveWithinBlock = ({ blockDetails, setBlocketails }) => {
  const dispatch = useDispatch()

  const [loader, setLoader] = useState(false)
  const [selectedLocker, setSelectedLocker] = useState()
  const [moveAfterLocker, setMoveAfterLocker] = useState()

  const onMove = () => {
    if (!selectedLocker) {
      dispatch(setNotification({ type: 'error', message: 'Please select locker' }))
      return
    }
    if (!moveAfterLocker) {
      dispatch(setNotification({ type: 'error', message: 'Please select move after locker' }))
      return
    }
    let payload = {
      item_date_time_id: blockDetails?.item_date_time_id,
      domain_id: blockDetails?.domain_id,
      location_id: blockDetails?.id,
      selected_locker: parseInt(selectedLocker),
      move_after_locker: parseInt(moveAfterLocker),
    }

    setLoader(true)
    request('move-with-in-block', 'POST', payload).then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 202) {
        request('get-location-data', 'POST', { location_id: blockDetails?.id }).then((res) => {
          dispatch(setNotification({ type: 'success', message: 'Locker moved successfully!' }))
          if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
            setBlocketails(res?.data)
          }
          setLoader(false)
        })
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message || 'Something went wrong!' }))
        setLoader(false)
      }
    })
    setSelectedLocker()
    setMoveAfterLocker()
  }

  return (
    <div>
      <AvForm>
        <div className='d-flex'>
          <div style={{ width: '12rem' }} className='mr-4'>
            <AvField
              type='select'
              name='selected_locker'
              value={selectedLocker}
              onChange={(e) => {
                setSelectedLocker(e.target.value)
              }}>
              <option>Locker Number</option>
              {blockDetails?.lockers?.map((item) => {
                return <option value={item?.id}>{item?.name}</option>
              })}
            </AvField>
          </div>
          <div style={{ width: '12rem' }} className='mr-4'>
            <AvField
              type='select'
              name='move_after'
           
              value={moveAfterLocker}
              onChange={(e) => {
                setMoveAfterLocker(e.target.value)
              }}>
              <option>Move After Locker</option>
              {blockDetails?.lockers?.map((item) => {
                return <option value={item?.id}>{item?.name}</option>
              })}
            </AvField>
          </div>

          <button
            className='btn text-white mb-4 ml-4'
            // disabled={!!loading}
            type='submit'
            onClick={() => {
              onMove()
            }}
            style={{ background: primary_color, fontWeight: 700 }}>
            {loader ? 'Saving...' : 'Save'}
          </button>
        </div>
      </AvForm>
    </div>
  )
}

export default MoveWithinBlock
