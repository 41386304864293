import { useState } from 'react'
import { Col, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap'
import Select from 'react-select'
import { RiDoorLine } from 'react-icons/ri'
import { primary_color } from '../../../Helper/uiHelper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPoundSign } from '@fortawesome/free-solid-svg-icons'
import { request } from '../../../Redux/Sagas/requests/api'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import Error from './error'

const DUMMY_TIER = [
  {
    title: 'Tier 1',
    rate: 10,
  },
  {
    title: 'Tier 2',
    rate: 10,
  },
  {
    title: 'Tier 3',
    rate: 10,
  },
  {
    title: 'Tier 4',
    rate: 10,
  },
]

const BlockRates = ({ setBlocketails, blockDetails, form, onCloseForm }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState()
  const [tierData, setTierData] = useState(blockDetails?.tier)

  const onChangeRate = (rate, index) => {
    const newTierData = tierData?.map((tier, i) => {
      if (index === i) {
        return {
          ...tier,
          rate: parseInt(rate),
        }
      } else {
        return tier
      }
    })
    setTierData(newTierData)
  }

  const onSubmitRate = () => {
    setLoading(true)
    let payload = {
      item_date_time_id: blockDetails?.item_date_time_id,
      domain_id: blockDetails?.domain_id,
      location_id: blockDetails?.id,
      tier: tierData,
    }
    request('add-item-price', 'POST', payload).then((res) => {
      if (res?.status === 200 || res?.status === 202 || res?.status === 201) {
        dispatch(setNotification({ type: 'success', message: 'Rate updated successfully!' }))
        request('get-location-data', 'POST', { location_id: blockDetails?.id }).then((res) => {
          if (res?.status === 200 || res?.status === 201 || res?.status === 202) {
            setBlocketails(res?.data)
          }
          setLoading(false)
        })
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message || 'Something went wrong' }))
        setLoading(false)
      }
    })
  }
  if (tierData?.length === 0) {
    return <Error message='Please add the locker to the block in order to include the applicable rates !' />
  }
  return (
    <div className='mt-5'>
      <div>
        {tierData?.map((tier, index) => {
          return (
            <div className='d-flex align-items-center'>
              <div
                key={index}
                onClick={() => {
                  // onChangeValue(item?.reserved, item?.id, item)
                }}
                className=' position-relative mr-5'
                style={{
                  width: '8rem',
                  margin: '0.1rem',
                  height: '10rem',
                  background: primary_color,
                  cursor: 'pointer',
                }}>
                {/* {!isDisplayZero ? (
                  <div
                    className='m-1 position-absolute subtitle1'
                    style={{
                      left: '0.5rem',
                      top: '0.25rem',
                      background: 'white',
                      padding: '0rem 0.5rem',
                      borderRadius: '14px',
                      fontWeight: '500',
                    }}>
                    <div>£{item?.price}</div>
                  </div>
                ) : null} */}

                <div className='position-absolute' style={{ top: '50%', left: '50%', transform: 'translate(-50% , -50%)' }}>
                  {/* <i class='fa-thin fa-door-closed' style={{ fontSize: '5rem' }}></i> */}
                  <RiDoorLine style={{ color: '#DADADA', fontSize: '6rem' }} />
                </div>
                <div
                  className='text-align-center mt-2 subtitle1'
                  style={{
                    color: '#EDF0F7',
                    position: 'absolute',
                    bottom: '1rem',
                    fontWeight: '500',
                    left: '50%',
                    transform: 'translate(-50%)',
                  }}>
                  {tier?.name}
                </div>
              </div>
              <div>
                <Label>Rate</Label>
                <InputGroup>
                  <InputGroupAddon addonType='prepand' style={{ cursor: 'pointer' }}>
                    <div className='input-dollar-icon input-group-text'>
                      <FontAwesomeIcon icon={faPoundSign} />
                    </div>
                  </InputGroupAddon>
                  <Input
                    type={'number'}
                    value={tier?.rate}
                    onChange={(e) => {
                      onChangeRate(e.target.value, index)
                    }}
                    // id={'amount'}
                    // value={extraChargeForm?.amount}
                  />
                </InputGroup>
              </div>
            </div>
          )
        })}
        <div className='mt-5'>
          <button
            className='btn rounded-pill mt-2 text-danger border-danger mr-3'
            type='button'
            onClick={(e) => {
              onCloseForm()
              e.preventDefault()
              e.stopPropagation()
            }}
            style={{ fontWeight: 700, width: '12rem' }}>
            Cancel
          </button>
          <button
            className='btn text-white rounded-pill mt-2'
            disabled={!!loading}
            type='submit'
            onClick={() => {
              onSubmitRate()
            }}
            style={{ background: primary_color, fontWeight: 700, width: '14rem' }}>
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default BlockRates
