import { AvForm } from 'availity-reactstrap-validation'
import RenderFields from '../../../../CMSComponent/Forms/renderFields'
import { useEffect, useState } from 'react'
import { primary_color } from '../../../../Helper/uiHelper'
import { request } from '../../../../Redux/Sagas/requests/api'
import { useDispatch, useSelector } from 'react-redux'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'
import { isSuccessResponse } from '../../../../helper'
import Loader from 'react-loaders'

const AddVoucher = ({ onCloseForm, defaultValue, grid_id, refetch }) => {
  const dispatch = useDispatch()
  const currentRowData = useSelector((state) => state.CMS.currentRow)
  const [loading, setLoading] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [formFieldValue, setFormFieldValue] = useState()
  const [form, setForm] = useState()

  useEffect(() => {
    setFormLoading(true)
    request('get-grid-form', 'POST', { grid_id }).then((res) => {
      if (isSuccessResponse(res)) {
        setForm(res?.data?.[0])
      } else {
        onCloseForm()
      }
      setFormLoading(false)
    })
  }, [grid_id])
  const onUpdateLocker = (values) => {
    request('add-voucher', 'POST', { domain_id: currentRowData?.[0]?.id, ...values }).then((res) => {
      if (isSuccessResponse(res, 'Voucher created!')) {
        
        refetch()
        onCloseForm()
      }
      setLoading(false)
    })
    
  }
  if (formLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 5rem)', alignItems: 'center' }}>
        <Loader type='ball-pulse' color='blue' />
      </div>
    )
  }
  return (
    <div className='m-4'>
      <div className='font-weight-bold mb-4 body1' style={{ color: primary_color }}>
        Create Voucher
      </div>
      <AvForm
        onValidSubmit={(e, values) => {
          setLoading(true)
          onUpdateLocker(values)
          // onSubmitBlockDetails(values)
        }}>
        <RenderFields
          allForm={[form]}
          content={[form]}
          twoCol={true}
          defaultFormValue={{}}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
          // defaultFormValue={blockDetails}
        />
        <div className='d-flex justify-content-end mt-5'>
          {' '}
          <button
            className='btn rounded-pill mt-2 text-danger border-danger mr-3'
            type='button'
            onClick={(e) => {
              onCloseForm()
              e.preventDefault()
              e.stopPropagation()
            }}
            style={{ fontWeight: 700, width: '12rem' }}>
            Cancel
          </button>
          <button
            className='btn text-white rounded-pill mt-2'
            disabled={!!loading}
            type='submit'
            style={{ background: primary_color, fontWeight: 700, width: '14rem' }}>
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </AvForm>
    </div>
  )
}

export default AddVoucher
